import React from 'react';
import styled from 'styled-components';

import { font, mixin } from 'shared/utils/styles';
import Modal from 'components/Modal';
import Button from 'components/Button';

export const StyledConfirmModal = styled(Modal)`
  padding: 30px;
`;

export const Title = styled.div`
  padding-bottom: 10px;
  ${font.medium}
  ${font.size(20)}
  line-height: 1.5;
`;

export const Message = styled.p`
  padding-bottom: 30px;
  white-space: pre-wrap;
  ${font.size(16)}
  color: rgba(0, 0, 0, 0.7); /* Set the message color to black with 70% opacity */
`;

export const Actions = styled.div`
  display: flex; /* Make the container a flexbox */
  justify-content: ${({ children }) =>
    React.Children.count(children) === 1 ? 'center' : 'space-between'};
  align-items: center; /* Vertically center the buttons */
  padding-top: 6px;
  width: 100%; /* Ensure the container takes full width */
  padding: 0
    ${({ children }) => (React.Children.count(children) === 1 ? '0' : '15%')}; /* Adjust padding based on button count */
`;

export const StyledButton = styled(Button)`
  ${mixin.boxShadowMedium}
  min-width: 120px; /* Set a minimum width for consistent button sizing */
  margin: ${({ children }) =>
    React.Children.count(children) === 1
      ? '0'
      : '0 20px'}; /* Center single button, add margin between two */
`;
