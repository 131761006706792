import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material/styles';
import { color, font, mixin } from 'shared/utils/styles';

const StyledLoadingButton = styled(LoadingButton)(({ variant }) => {
  const textColor = variant === 'secondary' ? color.textDark : '#ffffff';
  const backgroundColor_hover =
    variant === 'secondary'
      ? color.backgroundLight
      : mixin.lighten(color[variant], 0.15);

  const backgroundColor_active =
    variant === 'secondary'
      ? color.backgroundLightPrimary
      : mixin.lighten(color[variant], 0.1);

  return `
    ${mixin.boxShadowMedium}
    min-width: 120px; 
    margin: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    vertical-align: middle;
    line-height: 1;
    padding: 0px 12px;
    white-space: nowrap;
    border-radius: 2px;
    transition: all 0.1s;
    appearance: none;
    ${mixin.clickable}
    ${font.size(14.5)}
    ${font.medium}
    &:disabled {
      opacity: 0.6;
      cursor: default;
    }
    color: ${textColor};
    background: ${color[variant]};

    &:not(:disabled) {
      &:hover {
        background: ${backgroundColor_hover};
      }
      &:active {
        color: ${textColor};
        background: ${backgroundColor_active};
      }
    }

    &:disabled, 
    &.Mui-disabled {
      color: ${textColor}; 
    }

    &.MuiLoadingButton-loading {
      color: ${textColor};
      .MuiLoadingButton-loadingIndicator {
          position: absolute;
          left: 15px; /* Adjust based on desired spacing */
          top: 50%;
          transform: translateY(-50%); /* Vertically center the spinner */
          display: inline-block;
      }
    }
  `;
});

export default StyledLoadingButton;
