import React, { Fragment, useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { TaskTimer } from 'tasktimer';

import { Modal, Grid, Card } from '@material-ui/core';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { generate4DigitsIdentifier } from 'shared/utils/randomIdGenerator';
import pxToRem from 'assets/theme/functions/pxToRem';
import DropZone from 'pages/parts/DropZoneLegacy';
import useStyles from './styles';

import { removeLeadingSlash } from 'shared/utils/misc';
import {
  fileTypeIsValid,
  getStructuredFiles,
  calculateFolderSize,
  calcBatchTotalSize,
  findDuplicateAttachments,
} from 'shared/utils/fileHandling';
import {
  useUploadMissionsContextController,
  addNewAttachmentOnExistingIssue,
  setMissionStatusContext,
  contextUpdateUploadingSpeed,
  addNewAttachmentOnExistingResearchCase,
} from 'context/NewIssueContext';
import { uploadBatchAttachmentsToS3 } from 'pages/Dashboard/Research/components/NewCase/utils';
import {
  generateDirectoryList,
  generateFileList,
  startPeriodicStatusCheckForBatchAttachments,
  checkForBatchAttachmentsCompletion,
} from './utils';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import { STATUS, IssueStatus } from 'shared/constants/uploadAttachments';

const propTypes = {
  researchCase: PropTypes.object.isRequired,
  fetchResearchCase: PropTypes.func,
  isOpen: PropTypes.bool,
  handleModalClose: PropTypes.func,
  darkMode: PropTypes.bool,
};

export const ModalForUploadingMoreAttachments = ({
  researchCase,
  fetchResearchCase,
  isOpen,
  handleModalClose,
  darkMode,
}) => {
  const classes = useStyles();

  const [controllerUploadMissions, dispatchUploadMissions] =
    useUploadMissionsContextController();

  const [structuredAttachments, setStructuredAttachments] = useState([]);
  const [newStructuredAttachments, setNewStructuredAttachments] = useState([]);
  const [duplicatedAttachments, setDuplicatedAttachments] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [showWarnInvalidFileTypes, setShowWarnInvalidFileTypes] =
    useState(false);

  useEffect(() => {
    // const id = controllerUploadMissions.newAttachmentsMissionId;
    const id = controllerUploadMissions.newMissionIdentifierId;
    const status =
      id in controllerUploadMissions.missions
        ? controllerUploadMissions.missions[id].status
        : '';
    if (status === STATUS.INIT) {
      const mission = controllerUploadMissions.missions[id];
      uploadNewAttachment(mission);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newAttachmentsMissionId]);

  const handleClosingWarnInvalidFileWindow = () => {
    setInvalidFiles([]);
    setShowWarnInvalidFileTypes(false);
  };

  const handleDuplicateAttachments = async (action) => {
    switch (action) {
      case STATUS.REPLACE: {
        const tmp = structuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...tmp, ...newStructuredAttachments]);
        break;
      }
      case STATUS.IGNORE: {
        const nonDups = newStructuredAttachments.filter(
          (item) => !duplicatedAttachments.some((x) => item.name === x.name)
        );
        setStructuredAttachments([...structuredAttachments, ...nonDups]);
        break;
      }
      default:
        break;
    }
    setNewStructuredAttachments([]);
    setDuplicatedAttachments([]);
  };

  const handleFileDelete = (targetIndex) => async () => {
    setStructuredAttachments(
      structuredAttachments.filter((_, index) => index !== targetIndex)
    );
  };

  const handleDropZoneFiles = async (files) => {
    const validAttachments = [];
    const invalidFiles = [];

    // filter out files with invalid formats;
    for (var i = 0; i < files.length; i++) {
      let file = files[i];
      if (!fileTypeIsValid(file)) {
        await invalidFiles.push(file);
      } else {
        // const item = await handleSingleFile(file);
        const item = handleSingleFile(file);
        await validAttachments.push(item);
      }
    }
    if (invalidFiles.length > 0) {
      setInvalidFiles([...invalidFiles]);
      setShowWarnInvalidFileTypes(true);
    }

    const newItems = getStructuredFiles(validAttachments);
    newItems.forEach((item) => {
      calculateFolderSize(item);
    });
    const dups = findDuplicateAttachments(structuredAttachments, newItems);

    if (dups.length > 0) {
      setDuplicatedAttachments(dups);
      setNewStructuredAttachments(newItems);
    } else {
      setStructuredAttachments([...structuredAttachments, ...newItems]);
    }
  };

  const handleSingleFile = (f) => {
    const item = {
      type: 'file',
      file: f,
      name: f.name,
      fileFullPath: removeLeadingSlash(f.path),
      size: f.size,
    };
    return item;
  };

  const handleSendAttachmentsButtonClick = async () => {
    const batchTotalBytes = calcBatchTotalSize(structuredAttachments);

    addNewAttachmentOnExistingResearchCase(dispatchUploadMissions, {
      missionId: `${
        researchCase.caseIdentifierId
      }_${generate4DigitsIdentifier()}`,
      researchCaseId: researchCase.id,
      researchCaseIdentifierId: researchCase.caseIdentifierId,
      researchCaseCreatedAt: researchCase.createdAt,
      structuredAttachments: structuredAttachments,
      batchTotalBytes: batchTotalBytes,
    });
  };

  const uploadNewAttachment = async (mission) => {
    handleModalClose();
    setStructuredAttachments([]);

    const {
      missionId,
      researchCaseId,
      researchCaseIdentifierId,
      researchCaseCreatedAt,
      structuredAttachments,
      batchTotalBytes,
    } = mission;

    // Step 1: start a timer to track the upload speed of the upload mission that is about to start;
    const timer0 = new TaskTimer(1000); // milliseconds
    const task_uploadSpeed_checker = {
      id: 'heartbeat',
      tickInterval: 2, // number of ticks between 2 task runs;
      async callback() {
        // can also be an async function, returning a promise
        contextUpdateUploadingSpeed(dispatchUploadMissions, { missionId });
        if (![STATUS.INIT, STATUS.UPLOADING].includes(mission.status)) {
          timer0.stop();
        }
      },
    };
    timer0.add(task_uploadSpeed_checker).start();

    // Step 2: Upload the attachments to S3 bucket, and notify the backend to process it;
    let attachments;
    try {
      setMissionStatusContext(dispatchUploadMissions, {
        missionId,
        value: STATUS.UPLOADING,
      });
      attachments = await uploadBatchAttachmentsToS3({
        missionId,
        researchCaseId,
        researchCaseIdentifierId,
        researchCaseCreatedAt,
        structuredAttachments,
        batchTotalBytes,
        controllerUploadMissions,
        dispatchUploadMissions,
      });
    } catch (error) {
      // if cancelled, error = {message: 'canceled', name: 'CanceledError', code: 'ERR_CANCELED'}
      if (error.code === STATUS.ERR_CANCELED) {
        console.error(`Upload is aborted: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId,
          value: STATUS.UPLOAD_ABORTED,
        });
      } else {
        console.error(`Failed to upload batch of attachments: ${error}`);
        setMissionStatusContext(dispatchUploadMissions, {
          missionId,
          value: STATUS.UPLOAD_FAILED,
        });
      }
      return false;
    }

    setMissionStatusContext(dispatchUploadMissions, {
      missionId,
      value: STATUS.PROCESSING,
    });

    // Step 3: Notify the backend to start a periodic check on this batch of additional attachments;
    const attachmentIds = attachments.map((item) => item.attachmentId);
    await startPeriodicStatusCheckForBatchAttachments(
      researchCaseId,
      attachmentIds
    );

    // Step 4: while waiting for backend to process, poll every few seconds to check for new status;
    const timer = new TaskTimer(1000); // milliseconds
    const task1 = {
      id: 'heartbeat',
      tickInterval: 5, // number of ticks between 2 taskTuns; e.g. run every 5 sec;
      totalRuns: 1440, // times to run; max 2 hour before timeout;
      async callback(task) {
        // can also be an async function, returning a promise
        const issueStatusRes = await checkForBatchAttachmentsCompletion(
          researchCase.id,
          attachmentIds
        );

        if (issueStatusRes === IssueStatus.COMPLETE) {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId,
            value: STATUS.PROCESS_COMPLETE,
          });
          timer.stop();
          //
          await fetchResearchCase();
        }
        if (issueStatusRes === IssueStatus.FAILED) {
          setMissionStatusContext(dispatchUploadMissions, {
            missionId,
            value: STATUS.PROCESS_FAILED,
          });
          timer.stop();
        }
      },
    };
    timer.add(task1).start();
  };

  return (
    <Fragment>
      <Modal
        // disableEscapeKeyDown
        open={isOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            return false;
          }
          handleModalClose();
        }}
        className={classes.modalModal}>
        <Card className={classes.modalCard}>
          <MDBox py={2} px={3} my={2} mx={3}>
            <MDTypography variant="h4" mb={1}>
              {intl.get('issue_details_add_more_attachment_files')}
            </MDTypography>
            <MDTypography mb={1}>
              {intl.get('issue_details_use_drag_and_drop')}
            </MDTypography>

            {structuredAttachments.length > 0 && (
              <MDBox
                p={3}
                mb={3}
                style={{ overflowY: 'auto', maxHeight: '60vh' }}>
                <Grid container spacing={5}>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <MDBox
                        style={{
                          marginTop: '5px',
                          marginBottom: '45px',
                        }}>
                        <MDTypography>
                          {intl.get('new_text_files')}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    {generateFileList(structuredAttachments, handleFileDelete)}
                  </Grid>
                  <Grid item xs={6}>
                    <Grid item xs={12}>
                      <MDBox
                        style={{
                          marginTop: '5px',
                          marginBottom: '45px',
                        }}>
                        <MDTypography>
                          {intl.get('new_text_directories')}
                        </MDTypography>
                      </MDBox>
                    </Grid>
                    {generateDirectoryList(
                      structuredAttachments,
                      handleFileDelete,
                      darkMode
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            )}

            <Grid spacing={3} container>
              <Grid item xs={12}>
                <DropZone handleFiles={handleDropZoneFiles} />
              </Grid>

              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  disabled={structuredAttachments.length === 0}
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={handleSendAttachmentsButtonClick}>
                  {intl.get('issue_details_send')}
                </MDButton>
              </Grid>

              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={handleModalClose}>
                  {intl.get('issue_details_cancel')}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Modal>

      <Modal
        open={showWarnInvalidFileTypes}
        onClose={handleClosingWarnInvalidFileWindow}
        className={classes.modalModal}>
        <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
          <MDBox py={3} px={3} mt={3} mx={3}>
            <MDTypography variant="h3" mb={1}>
              {intl.get('new_modal_invalid_files_title')}
            </MDTypography>
            <MDTypography>
              {intl.get('new_modal_invalid_files_message_1')}
            </MDTypography>
            {invalidFiles.map((file, index) => (
              <MDTypography style={{ color: 'red' }} key={index}>
                {file.path}
              </MDTypography>
            ))}
            <br />
            <MDTypography mb={1}>
              {intl.get('new_modal_invalid_files_message_2')}
            </MDTypography>
          </MDBox>
        </Card>
      </Modal>

      <Modal
        open={duplicatedAttachments.length > 0}
        className={classes.modalModal}>
        <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
          <MDBox py={3} px={3} mt={3} mx={3}>
            <MDTypography variant="h3" mb={1}>
              {intl.get('new_modal_duplicate_files_message_1')}
            </MDTypography>
            {duplicatedAttachments.map((file, index) => (
              <MDTypography style={{ color: 'red' }} key={index}>
                {file.fileFullPath}
              </MDTypography>
            ))}
            <MDTypography>
              {intl.get('new_modal_duplicate_files_message_2')}
            </MDTypography>
            <br />
            <Grid
              spacing={3}
              container
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ marginTop: '20px' }}>
              <Grid xs={4} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '15%',
                  }}
                  onClick={() => {
                    handleDuplicateAttachments(STATUS.REPLACE);
                  }}>
                  {intl.get('new_modal_duplicate_files_replace')}
                </MDButton>
              </Grid>

              <Grid xs={4} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '15%',
                  }}
                  onClick={() => {
                    handleDuplicateAttachments(STATUS.IGNORE);
                  }}>
                  {intl.get('new_modal_duplicate_files_ignore')}
                </MDButton>
              </Grid>

              <Grid xs={4} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '70%',
                    marginLeft: '15%',
                  }}
                  onClick={() => {
                    handleDuplicateAttachments();
                  }}>
                  {intl.get('new_modal_duplicate_files_cancel')}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Modal>
    </Fragment>
  );
};

ModalForUploadingMoreAttachments.propTypes = propTypes;
