import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { useRouteMatch } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { IssuePriorityIcon2 } from 'components';
import { IssueState, IssueStatus } from 'shared/constants/issues';
import { Chip, Grid } from '@material-ui/core';
import { Tags } from 'shared/constants/tags';
import {
  IssueLink,
  Issue,
  KanbanItemTitle,
  Bottom,
  Assignees,
  AssigneeAvatar,
  IssueTitle,
  IssueProperty,
  IssueTags,
} from './Styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { UserProptypes } from 'shared/propTypes/userType';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonIcon from '@mui/icons-material/Person';

const propTypes = {
  projectUsers: PropTypes.arrayOf(PropTypes.shape(UserProptypes)).isRequired,
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  index: PropTypes.number.isRequired,
};

const ProjectBoardListIssue = ({ projectUsers, issue, index }) => {
  const match = useRouteMatch();
  const assignees = issue.userIds.map((userId) =>
    projectUsers.find((user) => user.id === userId)
  );

  // TODO Add the colors of the cards
  // const tags = () => {
  //   const Tags = {
  //     CT1: intl.get('case_tag_value_ct_1'),
  //     CT2: intl.get('case_tag_value_ct_2'),
  //     CT3: intl.get('case_tag_value_ct_3'),
  //     CT4: intl.get('case_tag_value_ct_4'),
  //     CT5: intl.get('case_tag_value_ct_5'),
  //     CT6: intl.get('case_tag_value_ct_6'),
  //     CT7: intl.get('case_tag_value_ct_7'),
  //     CT8: intl.get('case_tag_value_ct_8'),
  //     CT9: intl.get('case_tag_value_ct_9'),
  //     CT10: intl.get('case_tag_value_ct_10'),
  //     MRI1: intl.get('case_tag_value_mri_1'),
  //     MRI2: intl.get('case_tag_value_mri_2'),
  //     MRI3: intl.get('case_tag_value_mri_3'),
  //     MRI4: intl.get('case_tag_value_mri_4'),
  //     MRI5: intl.get('case_tag_value_mri_5'),
  //     MRI6: intl.get('case_tag_value_mri_6'),
  //     MRI7: intl.get('case_tag_value_mri_7'),
  //     MRI8: intl.get('case_tag_value_mri_8'),
  //     MRI9: intl.get('case_tag_value_mri_9'),
  //     MRI10: intl.get('case_tag_value_mri_10'),
  //     MRI11: intl.get('case_tag_value_mri_11'),
  //     MRI12: intl.get('case_tag_value_mri_12'),
  //     MRI13: intl.get('case_tag_value_mri_13'),
  //     MRI14: intl.get('case_tag_value_mri_14'),
  //     PhyExam1: intl.get('case_tag_value_phy_exam_1'),
  //     PhyExam2: intl.get('case_tag_value_phy_exam_2'),
  //     PhyExam3: intl.get('case_tag_value_phy_exam_3'),
  //     PhyExam4: intl.get('case_tag_value_phy_exam_4'),
  //     MRICor: intl.get('case_tag_value_mri_cor'),
  //   };
  //   const tagStrings = issue.tags.map((t) => Tags[t]);
  //   if (tagStrings.length > 0 && !tagStrings[0]) {
  //     return issue.tags.join(', ');
  //   }
  //   return tagStrings.join(`, `);
  // };

  return (
    <Draggable draggableId={issue.id.toString()} index={index}>
      {(provided, snapshot) => (
        <IssueLink
          to={{
            pathname: `${match.url}/issues/${issue.id}`,
            state: { trigger: 'byClick' },
          }}
          ref={provided.innerRef}
          data-testid="list-issue"
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Issue
            status={issue.status === IssueStatus.DONE}
            color={'blue'}
            isBeingDragged={snapshot.isDragging && !snapshot.isDropAnimating}>
            <KanbanItemTitle>
              <Grid container>
                <Grid item xs={12}>
                  <IssueTitle>
                    {issue.state === IssueState.WARNING && (
                      <WarningAmberIcon
                        color="warning"
                        fontSize="medium"
                        sx={{ margin: '0 3px' }}
                        data-testid="kanban-issue-warning-icon"
                      />
                    )}
                    <p
                      style={{ fontWeight: 'bold' }}
                      data-testid="kanban-issue-title">
                      {issue.title}
                    </p>
                  </IssueTitle>
                </Grid>

                <Grid xs={12} item>
                  <IssueProperty>
                    <LocationCityIcon
                      fontSize="medium"
                      sx={{ margin: '0 3px' }}
                    />
                    <p>{issue.srcOrgName}</p>
                  </IssueProperty>
                </Grid>

                <Grid xs={12} item>
                  <IssueProperty>
                    <PersonIcon fontSize="medium" sx={{ margin: '0 3px' }} />
                    <p>{issue.patientName}</p>
                  </IssueProperty>
                </Grid>
                <Grid xs={12} item>
                  <IssueTags>
                    {issue.tags.map((t) => (
                      <Chip
                        size="small"
                        key={t}
                        label={t}
                        style={{
                          margin: '2px 3px',
                          border: '2px solid',
                          backgroundColor: '#dfe1ef',
                        }}
                      />
                    ))}
                  </IssueTags>
                </Grid>
              </Grid>
            </KanbanItemTitle>
            <Bottom>
              <div>
                <IssuePriorityIcon2 priority={issue.priority} />
              </div>
              <Assignees>
                {assignees.map((user) => (
                  <AssigneeAvatar
                    key={user.id}
                    size={24}
                    name={user.firstName}
                  />
                ))}
              </Assignees>
            </Bottom>
          </Issue>
        </IssueLink>
      )}
    </Draggable>
  );
};

ProjectBoardListIssue.propTypes = propTypes;

export default ProjectBoardListIssue;
