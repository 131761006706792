import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';

const propTypes = {
  darkMode: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  handleRefreshClick: PropTypes.func,
  isRefreshing: PropTypes.bool,
};

const RefreshButton = ({
  darkMode,
  tooltipTitle,
  handleRefreshClick,
  isRefreshing,
}) => {
  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        data-testid="mui-icon-refresh"
        aria-label="refresh"
        disabled={isRefreshing}
        onClick={handleRefreshClick}
        sx={{
          color: darkMode ? '#fff' : '#333', // White color in dark mode, darker in light mode
          backgroundColor: 'transparent', // Transparent background to inherit from the parent
          '&:hover': {
            backgroundColor: darkMode ? '#444' : '#f0f0f0', // Dark grey in dark mode, light grey in light mode
            color: darkMode ? '#fff' : '#000', // Ensure good contrast in dark mode
          },
        }}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
};

RefreshButton.propTypes = propTypes;

export default RefreshButton;
