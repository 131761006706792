import React, { Fragment } from 'react';
import { withRouter } from 'react-router';
import ServiceTermsJaJP from './ServiceTermsJaJP';
import localData from 'shared/utils/localData';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import { USER_DATA } from 'shared/constants/users';

const DashboardServiceTerms = () => {
  const preferredLanguage = localData.get(USER_DATA.PREFFERED_LANGUAGE);

  return (
    <Fragment>
      <Card
        data-testid="service-terms-card"
        sx={{
          margin: 4,
          marginTop: 3,
          overflow: 'visible',
          height: '95vh',
          alignItems: 'center',
        }}>
        <MDBox
          data-testid="service-terms-mdbox"
          py={3}
          px={3}
          mt={3}
          mx={3}
          sx={{ height: '100%', width: '100%' }}>
          {preferredLanguage === 'ja-JP' && <ServiceTermsJaJP />}
          {preferredLanguage === 'en-US' && <ServiceTermsJaJP />}
          {preferredLanguage === 'zh-CN' && <ServiceTermsJaJP />}
        </MDBox>
      </Card>
    </Fragment>
  );
};

export default withRouter(DashboardServiceTerms);
