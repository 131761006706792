// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import { Collapse, Icon, ListItemButton } from '@mui/material';

import { useMaterialUIController } from 'context';
import MDTypography from 'components/MDTypography';
import { sidenavHeader } from 'components/SidenavList/Styles';
import { collapseArrow } from './Styles';

function SidenavListCollapse({ title, children, open, active, handleClick }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  return (
    <List
      component="nav"
      sx={{
        px: 2,
        my: 0.3,
      }}>
      <ListItemButton onClick={handleClick}>
        <Icon
          sx={(theme) =>
            collapseArrow(theme, {
              transparentSidenav,
              whiteSidenav,
              miniSidenav,
              open,
              active,
              darkMode,
            })
          }>
          expand_less
        </Icon>
        {title && (
          <MDTypography
            sx={(theme) =>
              sidenavHeader(theme, {
                transparentSidenav,
                miniSidenav,
                whiteSidenav,
                darkMode,
              })
            }>
            {title}
          </MDTypography>
        )}
      </ListItemButton>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </List>
  );
}

SidenavListCollapse.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  active: PropTypes.bool,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default SidenavListCollapse;
