import styled from 'styled-components';
import { color } from 'shared/utils/styles';

export const StyledAdsBlock = styled.section`
  padding: 4em;
  max-width: 300px;
  height: 80vh;
  position: relative;
  background: ${(props) =>
    props.darkMode ? color.topbarPrimary : color.addBarPrimaryLight};
`;

export const StyledAdContent = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;
