import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import MDBox from 'components/MDBox';
// import MDTypography from 'components/MDTypography';
import { Typography } from '@mui/material';
import MDButton from 'components/MDButton';
import { TextEditedContent } from 'components';
import { useMaterialUIController } from 'context';
import localData from 'shared/utils/localData';
import api from 'shared/utils/api';
import ServiceTermsJP from 'assets/documents/service-terms/service-terms.txt';
import { DOLPHIN_API } from 'shared/constants/apis';
import { LANGUAGE, USER_DATA } from 'shared/constants/users';
import { mixin, modalBorderRadius } from 'shared/utils/styles';

const DashboardServiceTermsPopUpWindow = () => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [userAgreedServiceTerms, setUserAgreedServiceTerms] = useState(false);
  const [serviceTermsContext, setServiceTermsContext] = useState('');

  const handleAgreeServiceTerms = () => {
    const variables = {
      data: {},
    };
    api.put(DOLPHIN_API.USER_SERVICE, variables).then(
      (data) => {
        window.location.reload();
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const preferredLanguage = localData.get(USER_DATA.PREFFERED_LANGUAGE);

  useEffect(() => {
    if (preferredLanguage === LANGUAGE.JAPANSESE) {
      fetch(ServiceTermsJP)
        .then((r) => r.text())
        .then((text) => {
          setServiceTermsContext(text);
        });
    }
  }, [preferredLanguage]);

  return (
    <Card
      sx={{
        margin: 4,
        marginTop: 3,
        width: 800,
        maxHeight: '90vh',
        borderRadius: modalBorderRadius.sm,
      }}>
      <MDBox px={3} mt={3} mx={3}>
        {/* <MDTypography mb={1}>
          {intl.get('dashboard_service_terms_modal_text_title')}
        </MDTypography>
        <MDTypography>
          {intl.get('dashboard_service_terms_modal_text_thanks')}
        </MDTypography> */}
        <Typography
          sx={{
            paddingBottom: '5px',
            lineHeight: 1.5,
            fontSize: '20px',
            fontFamily: 'CircularStdMedium',
            fontWeight: 'normal',
            color: 'rgba(0, 0, 0, 0.87)',
          }}>
          {'Welcome. Thank you for using DOCloud! '}
        </Typography>
        <Typography
          sx={{
            lineHeight: 1.5,
            fontSize: '20px',
            fontFamily: 'CircularStdMedium',
            fontWeight: 'normal',
            color: 'rgba(0, 0, 0, 0.87)',
          }}>
          {'Terms Of Use'}
        </Typography>
        <Typography
          sx={{
            whiteSpace: 'pre-wrap',
            color: 'rgba(0, 0, 0, 0.7)',
            fontSize: '14px',
            fontFamily: 'CircularStdBook',
          }}>
          {'Last updated June 30, 2021'}
        </Typography>
      </MDBox>
      <MDBox
        py={3}
        px={3}
        mt={3}
        mx={3}
        sx={{
          overflow: 'auto',
          ...mixin.simpleCustomScrollbar,
        }}>
        <div style={{ overflow: 'auto' }}>
          <TextEditedContent content={serviceTermsContext} />
        </div>
        <Grid
          spacing={3}
          container
          justify="flex-end"
          alignItems="flex-end"
          style={{ marginTop: '20px' }}>
          <Grid xs={4} item>
            <FormControlLabel
              style={{ color: darkMode ? 'white' : 'black' }}
              control={
                <Checkbox
                  icon={
                    <CheckBoxOutlineBlankIcon style={{ fill: '#66ccff' }} />
                  }
                  onChange={() => {
                    setUserAgreedServiceTerms(!userAgreedServiceTerms);
                  }}
                />
              }
              label={intl.get('dashboard_service_terms_modal_label_agree')}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox py={3} px={3} mx={3}>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12} display="flex" justifyContent="center">
            <MDButton
              variant="gradient"
              color="info"
              disabled={!userAgreedServiceTerms}
              style={{
                color: 'white',
                fontSize: '16px',
                marginTop: '5px',
                marginBottom: '15px',
              }}
              onClick={handleAgreeServiceTerms}>
              {intl.get('dashboard_service_terms_modal_button_proceed')}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default DashboardServiceTermsPopUpWindow;
