import React from 'react';
import PropTypes from 'prop-types';
// import intl from 'react-intl-universal';
import { useRouteMatch } from 'react-router-dom';
import { Draggable } from 'react-beautiful-dnd';
import { IssuePriorityIcon2 } from 'components';
import { Grid } from '@material-ui/core';
import LocationCityIcon from '@mui/icons-material/LocationCity';

import {
  IssueLink,
  Issue,
  KanbanItemTitle,
  Bottom,
  IssueTitle,
  IssueProperty,
} from './Styles';

import { UserProptypes } from 'shared/propTypes/userType';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import CaseStatsChips from './caseStatsChips';
import { IssueStatusKenshin } from 'shared/constants/kenshin/issues';

const propTypes = {
  projectUsers: PropTypes.arrayOf(PropTypes.shape(UserProptypes)).isRequired,
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  index: PropTypes.number.isRequired,
};

const ProjectBoardListIssue = ({ projectUsers, issue, index }) => {
  const match = useRouteMatch();
  return (
    <Draggable draggableId={issue.id.toString()} index={index}>
      {(provided, snapshot) => (
        <IssueLink
          to={{
            pathname: `${match.url}/issues/${issue.id}`,
            state: { trigger: 'byClick' },
          }}
          ref={provided.innerRef}
          data-testid="list-issue"
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Issue
            status={issue.progress === IssueStatusKenshin.DONE}
            color={'blue'}
            isBeingDragged={snapshot.isDragging && !snapshot.isDropAnimating}>
            <KanbanItemTitle>
              <Grid container>
                <Grid item xs={12}>
                  <IssueTitle>
                    <p
                      style={{ fontWeight: 'bold' }}
                      data-testid="kanban-kenshin-issue-title">
                      {issue.title}
                    </p>
                  </IssueTitle>
                </Grid>
                <Grid xs={12} item>
                  <IssueProperty>
                    <LocationCityIcon
                      fontSize="medium"
                      sx={{ margin: '0 3px' }}
                    />
                    <p>{issue.srcOrgName}</p>
                  </IssueProperty>
                </Grid>
              </Grid>
            </KanbanItemTitle>

            <Bottom>
              <div>
                <IssuePriorityIcon2 priority={issue.priority} />
              </div>
            </Bottom>
            <Bottom>
              {issue.progress !== 'DONE' && <CaseStatsChips issue={issue} />}
            </Bottom>
          </Issue>
        </IssueLink>
      )}
    </Draggable>
  );
};

ProjectBoardListIssue.propTypes = propTypes;

export default ProjectBoardListIssue;
