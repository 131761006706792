import { useState, Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CloseIcon from '@mui/icons-material/Close';

// self defined
import api from 'shared/utils/api';
// import pxToRem from 'assets/theme/functions/pxToRem';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
// import { useMaterialUIController } from 'context';
import {
  // isCurrentUserOrganizationManager,
  isCurrentUserTaskManager,
} from 'shared/utils/privilege';
import { Actions, StyledButton } from 'components/ConfirmModal/Styles';
import localData from 'shared/utils/localData';
import { USER_COMMENT } from 'shared/constants/comment';
import { DOLPHIN_API } from 'shared/constants/apis';
import { useStyles } from './styles';
import { getSelectedIssuesListTitle, getSelectedIssuesList } from './utils';
import ToggleStatus from './Status';
import ToggleState from './State';
import AssigneesReporter from './AssigneesReporter';
import SelectDoctorPopUpWindow from './SelectDoctorPopUpWindow';
import { mixin, modalBorderRadius, modalWidths } from 'shared/utils/styles';

const propTypes = {
  isOpened: PropTypes.bool,
  closeModal: PropTypes.func,
  selectedIssueIndexes: PropTypes.arrayOf(PropTypes.number).isRequired,
  setSelectedIssueIndexes: PropTypes.func,
  issues: PropTypes.array.isRequired,
  doctors: PropTypes.array.isRequired,
  updateLocalProjectIssues: PropTypes.func,
  handleOpenIssueDetails: PropTypes.func,
};

const BatchEditModal = ({
  isOpened,
  closeModal,
  selectedIssueIndexes,
  setSelectedIssueIndexes,
  issues,
  doctors,
  updateLocalProjectIssues,
  handleOpenIssueDetails,
}) => {
  const classes = useStyles();

  const selectedIssues = selectedIssueIndexes.map((index) => issues[index]);
  const firstIssue = selectedIssues[0]; // logically selectedIssues must contain at least one item;
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalUpdateData, setConfirmModalUpdateData] = useState();
  const [confirmModalInfo, setConfirmModalInfo] = useState({
    source: '',
    destination: '',
  });
  const [selectDoctorModalOpen, setSelectDoctorModalOpen] = useState(false);

  const batchUpdateIssues = (updatedFields, issues) => {
    api.optimisticBatchUpdateIssues(`${DOLPHIN_API.ISSUES_BATCH_UPDATE}`, {
      issues,
      updatedFields,
      setLocalData: (issueId, fields) => {
        // this fields is either `updatedFields` or `currentFields` above;
        // this is the local action that is being executed
        updateLocalProjectIssues(issueId, fields);
      },
    });
  };

  const currentOrganizationId = localData.get(USER_COMMENT.CURRENT_ORGID);
  const srcOrgId = selectedIssues[0].srcOrgId.toString();
  const isClient = parseInt(srcOrgId) === parseInt(currentOrganizationId);
  const isTaskManager = isCurrentUserTaskManager();

  const handleConfirmModalYesButtonClick = async () => {
    await batchUpdateIssues(confirmModalUpdateData, selectedIssues);
    setConfirmModalOpen(false);
    setSelectedIssueIndexes([]);
  };

  const handleConfirmModalNoButtonClick = () => {
    setConfirmModalOpen(false);
  };

  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  return (
    <Fragment>
      <Modal
        data-testid="batch-edit-modal"
        disableEscapeKeyDown
        open={isOpened}
        onClose={closeModal}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '50vw',
          zIndex: 'auto',
        }}>
        <Card
          sx={{
            minHeight: '30%',
            maxHeight: '75%',
            width: '60%',
            borderRadius: modalBorderRadius.sm,
          }}>
          <Grid container justify="flex-end">
            <Grid xs={12} item display="flex" justifyContent="flex-end">
              <IconButton
                aria-label="delete"
                size="small"
                variant="empty"
                onClick={closeModal}
                sx={{ paddingRight: 1, paddingTop: 1 }}
                // className={classes.closeIconButton}
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>

          <MDBox mt={1} mb={2} ml={4}>
            <MDTypography variant="h5" component="h2">
              {intl.get('shared_batch_edit_modal_text_edit_all')}
            </MDTypography>
          </MDBox>

          <Grid container spacing={2}>
            {/* batch edit tools */}

            <Grid item xs={1}></Grid>

            <Grid item xs={2}>
              <MDTypography variant="body2" style={{ marginTop: '1px' }}>
                {/* {intl.get('new_label_file_name')} */}
                {/* {'編集するにはクリックしてください: '} */}
              </MDTypography>
            </Grid>

            {/* batch change status */}
            <Grid item xs={2}>
              {isTaskManager === true && (
                <ToggleStatus
                  isClient={isClient}
                  issues={selectedIssues}
                  setConfirmModalInfo={setConfirmModalInfo}
                  setConfirmModalUpdateData={setConfirmModalUpdateData}
                  setConfirmModalOpen={setConfirmModalOpen}
                  setSelectDoctorModalOpen={setSelectDoctorModalOpen}
                />
              )}
            </Grid>

            {/* batch change state */}
            <Grid item xs={2} ml={-3}>
              <ToggleState
                selectedIssues={selectedIssues}
                setConfirmModalInfo={setConfirmModalInfo}
                setConfirmModalUpdateData={setConfirmModalUpdateData}
                setConfirmModalOpen={setConfirmModalOpen}
              />
            </Grid>

            {/* batch change doctors in charge */}
            <Grid item xs={3} ml={3}>
              <AssigneesReporter
                issue={firstIssue}
                doctors={doctors}
                setConfirmModalInfo={setConfirmModalInfo}
                setConfirmModalUpdateData={setConfirmModalUpdateData}
                setConfirmModalOpen={setConfirmModalOpen}
              />
            </Grid>

            {/* empty */}
            <Grid item xs={2}></Grid>
          </Grid>

          <MDBox my={1} ml={4}>
            <MDTypography variant="h5" component="h2">
              {/* {`現在選択されている依頼:  ${selectedIssues.length}  件`} */}
              {/* {intl.get('shared_batch_edit_modal_text_already_selected', {
                arg1: `${selectedIssues.length}`,
              })} */}
              {intl
                .get('shared_batch_edit_modal_text_already_selected')
                .replace('{arg1}', `${selectedIssues.length}`)}
            </MDTypography>
          </MDBox>

          {getSelectedIssuesListTitle()}

          {/* Horizontal Divider */}
          <div className={classes.horizontalDivider}></div>

          <Card
            sx={{
              boxShadow: 'none',
              // maxHeight: '90%',
              // width: '100%',
              // margin: 'auto',
              margin: '2px 2px 40px 2px',
              overflowY: 'scroll',
              ...mixin.simpleCustomScrollbar,
            }}>
            {getSelectedIssuesList(
              doctors,
              selectedIssues,
              handleOpenIssueDetails
            )}
          </Card>
        </Card>
      </Modal>

      {confirmModalOpen && (
        <Modal
          disableEscapeKeyDown
          open={confirmModalOpen}
          // onClose={closeConfirmModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: '50vw',
          }}>
          <Card
            sx={{
              maxHeight: '50%',
              width: modalWidths.xs,
              borderRadius: modalBorderRadius.sm,
              padding: '30px',
            }}>
            <MDBox>
              <Grid container justify="center" mb={5}>
                <Grid xs={12} item display="flex" justifyContent="flex-start">
                  <MDTypography variant="h5">
                    {intl
                      .get('shared_batch_edit_modal_text_hint_next')
                      .replace('{arg1}', `${selectedIssues.length}`)}
                  </MDTypography>
                </Grid>
              </Grid>
              <Actions>
                <StyledButton
                  variant="primary"
                  onClick={handleConfirmModalYesButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_yes')}
                </StyledButton>
                <StyledButton hollow onClick={handleConfirmModalNoButtonClick}>
                  {intl.get('kanban_confirm_popup_window_button_no')}
                </StyledButton>
              </Actions>
            </MDBox>
          </Card>
        </Modal>
      )}

      {selectDoctorModalOpen && (
        <SelectDoctorPopUpWindow
          doctors={doctors}
          selectedIssues={selectedIssues}
          batchUpdateIssues={batchUpdateIssues}
          confirmModalUpdateData={confirmModalUpdateData}
          setConfirmModalUpdateData={setConfirmModalUpdateData}
          selectDoctorModalOpen={selectDoctorModalOpen}
          setSelectDoctorModalOpen={setSelectDoctorModalOpen}
        />
      )}
    </Fragment>
  );
};

BatchEditModal.propTypes = propTypes;

export default BatchEditModal;
