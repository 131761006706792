import React, { Fragment, useState, useEffect, useRef } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { useLatest } from 'ahooks';
import { Typography, Modal, Grid } from '@material-ui/core';

import ReportList from './ReportList';
import ReportEditor from './ReportEditor';
import Confirm from './Confirm';
import { Button } from 'components';
import localData from 'shared/utils/localData';

import { useStyles } from './styles';
import { makeTagString } from 'pages/Dashboard/New/utils';
import ReportPreview from './ReportPreview';
import { USER_COMMENT } from 'shared/constants/comment';
import { USER_DATA } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';
import { IssueStatus } from 'shared/constants/issues';
import api from 'shared/utils/api';

import { InfoLabel } from '../Styles';

const propTypes = {
  issue: PropTypes.object.isRequired,
  isDoctor: PropTypes.bool.isRequired,
  isClient: PropTypes.bool.isRequired,
  fetchIssue: PropTypes.func.isRequired,
};

const ProjectBoardIssueDetailsReports = ({
  issue,
  isDoctor,
  isClient,
  fetchIssue,
}) => {
  const classes = useStyles();

  // for reporting modal
  const [reportEditorModalOpen, setReportEditorModalOpen] = useState(false);
  const [reportEditorIndex, setReportEditorIndex] = useState(-1);
  const latestReportEditorIndex = useLatest(reportEditorIndex);
  const [reportContent, setReportContent] = useState({
    fileName: '',
    issueId: '',
    issueTitle: '',
    issueDate: '',
    patientName: '',
    patientGender: '',
    patientBirthdayDate: '',
    doctorsFromSrcOrg: '',
    doctorsFromDstOrg: '',
    department: '',
    srcOrgName: '',
    dsrOrgname: '',
    tags: '',
    description: '',
    measures: '',
    diagnosis: '',
    keyImages: [],
    reportDate: '',
  });

  const latestReportContent = useLatest(reportContent);
  const [reportPreviewModalOpen, setReportPreviewModalOpen] = useState(false);
  const [previewReportContent, setPreviewReportContent] = useState({
    fileName: '',
    issueId: '',
    issueTitle: '',
    issueDate: '',
    patientName: '',
    patientGender: '',
    patientBirthdayDate: '',
    doctorsFromSrcOrg: '',
    doctorsFromDstOrg: '',
    department: '',
    srcOrgName: '',
    dsrOrgname: '',
    tags: '',
    description: '',
    measures: '',
    diagnosis: '',
    keyImages: [],
    reportDate: '',
  });
  const latestPreviewReportContent = useLatest(previewReportContent);

  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState(false);
  const [confirmPublishModalOpen, setConfirmPublishModalOpen] = useState(false);
  const [reportToPublishIndex, setReportToPublishIndex] = useState(-1);
  const latestReportToPublishIndex = useLatest(reportToPublishIndex);

  const issueIsDone = issue.status === IssueStatus.DONE;

  const handleMessageFromViewer = async (event) => {
    if (
      event.type === 'message' &&
      // event.origin === process.env.REACT_APP_OHIF_VIEWER_URL
      // event.origin === process.env.REACT_APP_OHIF_VIEWER_INTERNAL_URL
      event.data.messageType === 'keyImage'
    ) {
      const fileName = event.data.fileName;
      const fileBuffer = await event.data.blob.arrayBuffer();
      let newKeyImages = [...latestReportContent.current.keyImages];
      newKeyImages.push({
        fileName: fileName,
        fileBuffer: Buffer.from(fileBuffer),
      });
      setReportContent((prevState) => {
        return {
          ...prevState,
          keyImages: newKeyImages,
        };
      });
    } else if (
      event.type === 'message' &&
      event.data.messageType === 'measurement'
    ) {
      // console.log('Get measurement');
    }
  };

  const handleCommentButtonClick = () => {};

  const handleDownloadButtonClick = () => {};

  const handlePreviewButtonClick = (report) => {
    setPreviewReportContent({
      ...report.content,
    });
    setReportPreviewModalOpen(true);
  };

  const handleEditButtonClick = (report, index) => {
    if (latestReportEditorIndex.current !== index) {
      setReportContent({
        ...report.content,
      });
      setReportEditorIndex(index);
    }
    setReportEditorModalOpen(true);
  };

  const handleSubmitButtonClick = (report, index) => {};

  const handleNewReportEditButtonClick = () => {
    if (latestReportEditorIndex.current !== -1) {
      initReportContent();
      setReportEditorIndex(-1);
    }
    setReportEditorModalOpen(true);
  };

  const handlePublishButtonClick = (report, index) => {
    setReportToPublishIndex(index);
    setConfirmPublishModalOpen(true);
  };

  const initReportContent = () => {
    // get tags
    // TODO: Chagne to another tag system
    const CTTagsArray = [];
    const MRITagsArray = [];
    const PhyExamTagsArray = [];
    const tags = issue.tags;

    const tagsObject = {};
    for (var i = 0; i < tags.length; i++) {
      tagsObject[tags[i]] = true;
    }
    for (var j = 0; j < tags.length; j++) {
      const key = tags[j];
      const res = makeTagString(key, tagsObject);
      if (res) {
        if (key.startsWith('CT')) CTTagsArray.push(res);
        if (key.startsWith('MRI')) MRITagsArray.push(res);
        if (key.startsWith('PhyExam')) PhyExamTagsArray.push(res);
      }
    }
    // const tagsText =
    //   `${intl.get('case_tag_value_section_1')}: ${CTTagsArray.join(', ')}; ` +
    //   `${intl.get('case_tag_value_section_2')}: ${MRITagsArray.join(', ')}; ` +
    //   `${intl.get('case_tag_value_section_3')}: ${PhyExamTagsArray.join(', ')}`;

    const tagsText = issue.tags.join('; ');

    setReportContent({
      fileName: 'report',
      issueId: issue.id,
      issueTitle: issue.title,
      issueDate: issue.createdAt.split('T')[0],
      patientName: issue.patientName,
      patientGender: issue.patientGender,
      patientBirthdayDate: issue.patientBirthdayDate,
      doctorsFromSrcOrg: issue.doctorsInCharge.join(', '),
      doctorsFromDstOrg: '',
      department: issue.department,
      srcOrgName: issue.srcOrgName,
      dsrOrgname: issue.dstOrgName,
      tags: tagsText,
      description: issue.descriptionText,
      measures: 'This is a measure',
      diagnosis: 'This is the diagnosis...',
      keyImages: [],
      reportDate: 'Today',
    });
  };

  const saveReportContent = async (index) => {
    try {
      if (index === -1) {
        // new report
        let newKeyImages = [];
        latestReportContent.current.keyImages.forEach((image) => {
          newKeyImages.push({
            fileName: image.fileName,
            fileBuffer: Buffer.from(image.fileBuffer).toString(),
          });
        });

        const apiVariables = {
          'Content-Type': 'multipart/form-data',
          data: {
            fileName: latestReportContent.current.fileName,
            creatorId: localData.get(USER_DATA.CURRENT_USER_ID),
            isPublished: false,
            content: {
              ...latestReportContent.current,
              // keyImages: newKeyImages,
            },
          },
          params: {
            organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
            issueId: issue.id,
          },
        };

        const data = await api.post(
          `${DOLPHIN_API.REPORTS}/create`,
          apiVariables
        );
        window.location.reload();
        // await fetchIssue();
      } else {
        // old report
        const apiVariables = {
          'Content-Type': 'multipart/form-data',
          data: {
            fileName: latestReportContent.current.fileName,
            creatorId: localData.get(USER_DATA.CURRENT_USER_ID),
            content: {
              ...latestReportContent.current,
            },
            index: index,
            reportId: issue.reports[index].id,
          },
          params: {
            organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
            issueId: issue.id,
          },
        };

        const data = await api.post(
          `${DOLPHIN_API.REPORTS}/update`,
          apiVariables
        );
        window.location.reload();
        // await fetchIssue();
      }
    } catch (error) {
      console.log('save report: ', error);
    }
  };

  const publishReport = async (index) => {
    try {
      const apiVariables = {
        'Content-Type': 'multipart/form-data',
        data: {
          reportId: issue.reports[index].id,
        },
        params: {
          organizationId: localData.get(USER_COMMENT.CURRENT_ORGID),
          issueId: issue.id,
        },
      };

      const data = await api.post(
        `${DOLPHIN_API.REPORTS}/publish`,
        apiVariables
      );
      window.location.reload();
      // await fetchIssue();
    } catch (error) {
      console.log('publish report: ', error);
    }
  };

  useEffect(() => {
    initReportContent();
    window.addEventListener('message', handleMessageFromViewer);
    return () => {
      window.removeEventListener('message', handleMessageFromViewer);
    };
  }, []);

  return (
    <Fragment>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <InfoLabel
            style={{
              marginTop: '10px',
              marginBottom: '10px',
            }}>
            {intl.get('issue_details_report_list_label_reports')}
          </InfoLabel>
        </Grid>
        {!isClient && isDoctor && !issueIsDone && (
          <Grid item>
            <Button
              variant="primary"
              sx={{ justifyContent: 'start' }}
              onClick={handleNewReportEditButtonClick}>
              {intl.get('issue_details_report_editor_button_open')}
            </Button>
          </Grid>
        )}
      </Grid>
      <ReportList
        issue={issue}
        isDoctor={isDoctor}
        isClient={isClient}
        handleCommentButtonClick={handleCommentButtonClick}
        handleDownloadButtonClick={handleDownloadButtonClick}
        handlePreviewButtonClick={handlePreviewButtonClick}
        handleEditButtonClick={handleEditButtonClick}
        handleSubmitButtonClick={handleSubmitButtonClick}
        handlePublishButtonClick={handlePublishButtonClick}
      />
      {/* {!isClient && isDoctor && !issueIsDone && (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Button
              data-testid="issue_details_report_editor_button"
              variant="primary"
              sx={{ justifyContent: 'start' }}
              onClick={handleNewReportEditButtonClick}>
              {intl.get('issue_details_report_editor_button_open')}
            </Button>
          </Grid>
        </Grid>
      )} */}
      {!isClient && isDoctor && (
        <Modal
          open={reportEditorModalOpen}
          onClose={() => setReportEditorModalOpen(false)}
          className={classes.reportingModal}>
          <div className={classes.reportingEditorModalPaper}>
            <ReportEditor
              issue={issue}
              reportContent={latestReportContent.current}
              setReportContent={setReportContent}
              reportEditorIndex={latestReportEditorIndex.current}
              saveReportContent={() => {
                setConfirmSaveModalOpen(true);
                setReportEditorModalOpen(false);
              }}
              modalClose={() => setReportEditorModalOpen(false)}
            />
          </div>
        </Modal>
      )}
      <Modal
        data-testid="reportPreviewModal"
        open={reportPreviewModalOpen}
        onClose={() => setReportPreviewModalOpen(false)}
        className={classes.reportingModal}>
        <div className={classes.reportingPreviewModalPaper}>
          <ReportPreview
            reportContent={latestPreviewReportContent.current}
            modalClose={() => setReportPreviewModalOpen(false)}
          />
        </div>
      </Modal>
      <Confirm
        ifOpen={confirmSaveModalOpen}
        confirmMessage={intl.get(
          'issue_details_report_confirm_modal_text_message_save'
        )}
        onClose={() => {
          setConfirmSaveModalOpen(false);
        }}
        onConfirm={() => {
          saveReportContent(latestReportEditorIndex.current);
          setConfirmSaveModalOpen(false);
        }}
        onCancel={() => {
          setReportEditorModalOpen(true);
          setConfirmSaveModalOpen(false);
        }}
        confirmButtonText={intl.get(
          'issue_details_report_confirm_modal_button_save'
        )}
        cancelButtonText={intl.get(
          'issue_details_report_confirm_modal_button_cancel'
        )}
      />
      <Confirm
        ifOpen={confirmPublishModalOpen}
        confirmMessage={intl.get(
          'issue_details_report_confirm_modal_text_message_publish'
        )}
        onClose={() => {
          setConfirmPublishModalOpen(false);
        }}
        onConfirm={() => {
          publishReport(latestReportToPublishIndex.current);
          setConfirmPublishModalOpen(false);
        }}
        onCancel={() => {
          setConfirmPublishModalOpen(false);
        }}
        confirmButtonText={intl.get(
          'issue_details_report_confirm_modal_button_publish'
        )}
        cancelButtonText={intl.get(
          'issue_details_report_confirm_modal_button_cancel'
        )}
      />
    </Fragment>
  );
};

ProjectBoardIssueDetailsReports.propTypes = propTypes;

export default ProjectBoardIssueDetailsReports;
