import styled from 'styled-components';

import { font } from 'shared/utils/styles';

export const EditorCont = styled.div`
  border-radius: 15px;
  background-color: ${(props) => props.theme.palette.surface.main};

  .ql-toolbar.ql-snow {
    border-radius: 15px 15px 0 0;
    border: 1px solid ${(props) => props.theme.palette.surface.main};
    background-color: ${(props) => props.theme.palette.surface.main};
    color: ${(props) => props.theme.palette.text.main};
    border-bottom: none;
  }
  .ql-container.ql-snow {
    height: 75%;
    border-radius: 0 0 15px 15px;
    border: 1px solid ${(props) => props.theme.palette.surface.main};
    color: ${(props) => props.theme.palette.text.main};
    border-top: none;
    background-color: ${(props) => props.theme.palette.surface.main};
    ${font.size(15)}
    ${font.regular}
  }
  .ql-editor.ql-blank::before {
    color: gray;
  }
  .ql-editor {
    min-height: 110px;
  }
  .ql-toolbar .ql-stroke {
    fill: none;
    stroke: ${(props) => props.theme.palette.text.main};
  }

  .ql-toolbar .ql-fill {
    fill: ${(props) => props.theme.palette.text.main};
    stroke: none;
  }

  .ql-toolbar .ql-picker {
    color: ${(props) => props.theme.palette.text.main};
  }
`;
