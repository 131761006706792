import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

import { color, font, mixin } from 'shared/utils/styles';

export const Content = styled.div`
  display: flex;
  padding: 0 30px 60px;
`;

export const Left = styled.div`
  width: 65%;
  padding-right: 50px;

  // make the left side scrollable
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  min-height: 500px;
  max-height: 800px;
  width: 80%;
  border-radius: 3px;
  overflow-y: auto;

  ${mixin.simpleCustomScrollbar}
`;

export const Right = styled.div`
  width: 35%;
  padding-top: 5px;
`;

export const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 18px 0;
`;

export const TopActionsRight = styled.div`
  display: flex;
  align-items: center;
  & > * {
    margin-left: 4px;
  }
`;

export const SectionTitle = styled.div`
  margin: 24px 0 5px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(12.5)}
  ${font.bold}
`;

export const InfoLabel = styled.div`
  margin: 24px 0 8px;
  text-transform: uppercase;
  color: ${color.textMedium};
  ${font.size(13.5)}
  ${font.bold}
`;

export const useStyles = makeStyles((theme) => ({
  viewportModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewportModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '85vw',
    height: '95vh',
  },
  previewModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  previewModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '85vw',
    maxHeight: '95vh',
  },
  reportingModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  reportingModalPaper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '85vw',
    height: '95vh',
  },
}));

// this will place the stamp at the bottom right corner of issue details modal window;
// export const StampBase = styled.div`
//   display: inline-flex;
//   margin-top: 40px;
//   margin-left: 40px;
//   align-items: center;
//   justify-content: center;
//   border-radius: 0%; // 50% is Circular shape
//   border: 2px solid #e74c3c;
//   color: #e74c3c; // Text color
//   padding: 10px; // Padding around the text
//   width: 100px; // Stamp size
//   height: 50px; // Stamp size
//   // transform: rotate(-15deg); // Rotate the stamp for effect
//   box-shadow: 0px 0px 8px rgba(0, 0, 0, 0); // Optional: add some shadow
//   position: absolute;
//   bottom: 15px;
//   right: 15px;
// `;

export const StampBase = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0%; // 50% is Circular shape
  border: 2px solid #e74c3c;
  color: #e74c3c; // Text color
  padding: 10px; // Padding around the text
  width: 80px; // Stamp size
  height: 30px; // Stamp size
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0); // Optional: add some shadow
`;

export const StampText = styled.div`
  width: 100%; // spans the full width of its container
  font-weight: bold; // Bold text
  font-size: 1.2rem; // Text size
  user-select: none; // Prevent text selection
  pointer-events: none; // Prevent mouse events
  letter-spacing: 0em; // spacing between characters

  text-align: center; // Center the text horizontally
  display: flex; // vertical centering
  justify-content: center; // Center content horizontally in the flex container
  align-items: center; // Center content vertically in the flex container
`;
