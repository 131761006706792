import { Button as BaseButton } from '@mui/base/Button';
import { styled } from '@mui/material/styles';
import { color, font, mixin } from 'shared/utils/styles';

const StyledButton = styled(BaseButton)(({ variant }) => {
  const textColor = variant === 'secondary' ? color.textDark : '#fff';
  const backgroundColor_hover =
    variant === 'secondary'
      ? color.backgroundLight
      : mixin.lighten(color[variant], 0.15);

  const backgroundColor_active =
    variant === 'secondary'
      ? color.backgroundLightPrimary
      : mixin.lighten(color[variant], 0.1);

  return `
    ${mixin.boxShadowMedium}
    min-width: 120px; 
    margin: 0 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    vertical-align: middle;
    line-height: 1;
    padding: 0px 12px;
    white-space: nowrap;
    border-radius: 2px;
    transition: all 0.1s;
    appearance: none;
    ${mixin.clickable}
    ${font.size(14.5)}
    ${font.medium}
    &:disabled {
      opacity: 0.6;
      cursor: default;
    }

    color: ${textColor};
    background: ${color[variant]};
    &:not(:disabled) {
      &:hover {
        background: ${backgroundColor_hover};
      }
      &:active {
        background: ${backgroundColor_active};
      }
    }
  `;
});

export default StyledButton;
