import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';

import { Typography, Grid } from '@mui/material';
import { Button } from 'components';
import MDBox from 'components/MDBox';

import { IssuePropTypes } from 'shared/propTypes/issueType';
import { USER_DATA } from 'shared/constants/users';
import { IssueStatus } from 'shared/constants/issues';
import localData from 'shared/utils/localData';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes),
  isClient: PropTypes.bool.isRequired,
  isDoctor: PropTypes.bool.isRequired,
  handleCommentButtonClick: PropTypes.func.isRequired,
  handleDownloadButtonClick: PropTypes.func.isRequired,
  handlePreviewButtonClick: PropTypes.func.isRequired,
  handleEditButtonClick: PropTypes.func.isRequired,
  handleSubmitButtonClick: PropTypes.func.isRequired,
  handlePublishButtonClick: PropTypes.func.isRequired,
};

const ProjectBoardIssueDetailsReportList = ({
  issue,
  isClient,
  isDoctor,
  handleCommentButtonClick,
  handleDownloadButtonClick,
  handlePreviewButtonClick,
  handleEditButtonClick,
  handleSubmitButtonClick,
  handlePublishButtonClick,
}) => {
  const username = localData.get(USER_DATA.USERNAME);
  const issueIsDone = issue.status === IssueStatus.DONE;
  return (
    <Fragment>
      {issue.reports.map((item, index) => {
        // TODO: get publish previlege according to the previlege system
        const hasPublishPrevilege = true; // Debug

        const isSubmitted = true; // Debug

        const isPublished = item.isPublished;

        const isEditable =
          !isPublished &&
          !isClient &&
          isDoctor &&
          item.creator.username === username &&
          !issueIsDone;

        const isPreview = isPublished || !isClient;

        const canSubmit = true; // Debug

        const canPublish =
          !isPublished &&
          !isClient &&
          isDoctor &&
          hasPublishPrevilege &&
          !issueIsDone;

        return isClient && !isPublished ? (
          <Fragment></Fragment>
        ) : (
          <MDBox style={{ marginBottom: '5px' }} key={index}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography
                  variant="body2"
                  style={{ wordWrap: 'break-workd' }}
                  data-testid={`report:${item.fileName}`}>
                  {isPublished && !isClient
                    ? item.fileName +
                      intl.get('issue_details_report_list_text_published')
                    : item.fileName}
                </Typography>
              </Grid>

              {/* 
              <Grid item xs={2}>
                <Button
                  icon="page"
                  iconSize={20}
                  variant="empty"
                  onClick={() => handleCommentButtonClick(item)}>
                  {intl.get(
                    'issue_details_report_list_button_quote_filename_in_comment'
                  )}
                </Button>
              </Grid>

              <Grid item xs={2}>
                <Button
                  icon="arrow-down"
                  iconSize={20}
                  variant="empty"
                  onClick={() => handleDownloadButtonClick(item)}>
                  {intl.get('issue_details_report_list_button_download')}
                </Button>
              </Grid> */}

              {isPreview && (
                <Grid item xs={2}>
                  <Button
                    data-testid={`report_button_preview:${item.fileName}`}
                    icon="board"
                    iconSize={20}
                    variant="empty"
                    onClick={() => handlePreviewButtonClick(item)}>
                    {intl.get('issue_details_report_list_button_preview')}
                  </Button>
                </Grid>
              )}

              {isEditable && (
                <Grid item xs={2}>
                  <Button
                    data-testid={`report_button_edit:${item.fileName}`}
                    icon="board"
                    iconSize={20}
                    variant="empty"
                    onClick={() => handleEditButtonClick(item, index)}>
                    {intl.get('issue_details_report_list_button_edit')}
                  </Button>
                </Grid>
              )}

              {/* TODO: implement */}
              {/* {canSubmit && (
                <Grid item xs={2}>
                  <Button
                    icon="board"
                    iconSize={20}
                    variant="empty"
                    onClick={() => handleSubmitButtonClick(item, index)}>
                    {intl.get('issue_details_report_list_button_submit')}
                  </Button>
                </Grid>
              )} */}

              {canPublish && (
                <Grid item xs={2}>
                  <Button
                    data-testid={`report_button_publish:${item.fileName}`}
                    icon="board"
                    iconSize={20}
                    variant="empty"
                    onClick={() => handlePublishButtonClick(item, index)}>
                    {intl.get('issue_details_report_list_button_publish')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </MDBox>
        );
      })}
    </Fragment>
  );
};

ProjectBoardIssueDetailsReportList.propTypes = propTypes;

export default ProjectBoardIssueDetailsReportList;
