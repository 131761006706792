import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, TextField } from '@material-ui/core';
import intl from 'react-intl-universal';

import ResultChip from '../../DiagnosticResult/ResultChip';

import {
  Content,
  Title,
  Line,
  Key,
  Value,
  ValueFill,
  CloseButton,
} from './styles';
import { addDiagnosis } from '../../api';

const propTypes = {
  assignments: PropTypes.array.isRequired,
  diagnosis: PropTypes.any.isRequired,
  diagnosticNote: PropTypes.any.isRequired,
  setDiagnosticNote: PropTypes.any.isRequired,
  currentIndex: PropTypes.any.isRequired,
  updateLocalAssignments: PropTypes.func.isRequired,
  handleGoToNext: PropTypes.func.isRequired,
  showPrevCase: PropTypes.bool.isRequired,
  closePrevCase: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

const ViewerDiagnosis = ({
  assignments,
  currentIndex,
  diagnosis,
  diagnosticNote,
  setDiagnosticNote,
  updateLocalAssignments,
  handleGoToNext,
  showPrevCase,
  closePrevCase,
  readOnly,
}) => {
  const handleNoteChange = (event) => {
    setDiagnosticNote(event.target.value);
  };

  const handleClickSick = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_SICK',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    // updateLocalData(currentIndex, {
    //   diagnosis: 'DIAGNOSED_SICK',
    //   diagnosticNote: diagnosticNote,
    // });
    updateLocalAssignments(currentIndex, {
      diagnosis: 'DIAGNOSED_SICK',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    if (!showPrevCase) {
      handleGoToNext();
    }
  };

  const handleClickUnknow = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_UNKNOWN',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    // updateLocalData(currentIndex, {
    //   diagnosis: 'DIAGNOSED_UNKNOWN',
    //   diagnosticNote: diagnosticNote,
    // });
    updateLocalAssignments(currentIndex, {
      diagnosis: 'DIAGNOSED_UNKNOWN',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    if (!showPrevCase) {
      handleGoToNext();
    }
  };

  const handleClickHealthy = async () => {
    await addDiagnosis(
      assignments[currentIndex].id,
      'DIAGNOSED_HEALTHY',
      // latestDiagnosticNote.current
      diagnosticNote
    );
    // updateLocalData(currentIndex, {
    //   diagnosis: 'DIAGNOSED_HEALTHY',
    //   diagnosticNote: diagnosticNote,
    // });
    updateLocalAssignments(currentIndex, {
      diagnosis: 'DIAGNOSED_HEALTHY',
      diagnosticNote: diagnosticNote,
    });
    // setDiagnosticNote('');
    if (!showPrevCase) {
      handleGoToNext();
    }
  };

  // Keyboard shortcut
  const handleKeyPress = useCallback(async (e) => {
    const { code, ctrlKey, altKey, shiftKey } = e;
    if (shiftKey && e.key === 'Enter') {
      handleClickHealthy();
    }
  }, []);

  useEffect(() => {
    // attach the event listener
    document.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <Fragment>
      <Content>
        <Line>
          <Title>
            {showPrevCase
              ? readOnly
                ? intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_prev_case'
                  ) +
                  intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_diagnosis'
                  )
                : intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_curr_case'
                  ) +
                  intl.get(
                    'dashboard_received_assignment_kenshin_viewer_diagnosis_text_diagnosis'
                  )
              : intl.get(
                  'dashboard_received_assignment_kenshin_viewer_diagnosis_text_diagnosis'
                )}
          </Title>
          {readOnly && (
            <CloseButton onClick={closePrevCase}>{'Close'}</CloseButton>
          )}
        </Line>
        <ResultChip
          result={readOnly ? diagnosis : assignments[currentIndex].diagnosis}
        />
        <Line>
          <ValueFill>
            {intl.get(
              'dashboard_received_assignment_kenshin_viewer_diagnosis_text_note'
            )}
          </ValueFill>
        </Line>
        <Line>
          {readOnly && (
            <TextField
              variant="outlined"
              disabled
              fullWidth
              multiline
              value={diagnosticNote}
              onChange={null}
            />
          )}
          {!readOnly && (
            <TextField
              variant="outlined"
              fullWidth
              multiline
              value={diagnosticNote}
              onChange={handleNoteChange}
            />
          )}
        </Line>
        <Line />
        <Line />
        {!readOnly && (
          <Line>
            <Grid container>
              <Grid container item xs={4} justifyContent="center">
                <Button
                  data-testid="diagnosis-button-sick"
                  variant="contained"
                  color="secondary"
                  onClick={handleClickSick}>
                  {intl.get('kenshin_case_diagnostic_result_diagnosed_sick')}
                </Button>
              </Grid>
              <Grid
                container
                item
                xs={4}
                justifyContent="center"
                onClick={handleClickUnknow}>
                <Button
                  data-testid="diagnosis-button-unknown"
                  variant="contained"
                  color="warning">
                  {intl.get('kenshin_case_diagnostic_result_diagnosed_unknown')}
                </Button>
              </Grid>
              <Grid container item xs={4} justifyContent="center">
                <Button
                  data-testid="diagnosis-button-healthy"
                  variant="contained"
                  color="primary"
                  onClick={handleClickHealthy}>
                  {intl.get('kenshin_case_diagnostic_result_diagnosed_healthy')}
                </Button>
              </Grid>
            </Grid>
          </Line>
        )}
      </Content>
    </Fragment>
  );
};

ViewerDiagnosis.propTypes = propTypes;

export default ViewerDiagnosis;
