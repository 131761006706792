import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import MDBox from 'components/MDBox';
import iconNewSendbackLight from 'assets/images/icons/icon-homepage-new-sendback-light.svg';
import iconNewSendbackDark from 'assets/images/icons/icon-homepage-new-sendback-dark.svg';
import iconNewReceivedLight from 'assets/images/icons/icon-homepage-new-received-light.svg';
import iconNewReceivedDark from 'assets/images/icons/icon-homepage-new-received-dark.svg';
import HomeCard from '../HomeCard';

const propTypes = {
  numNewReceived: PropTypes.number.isRequired,
  numNewSendback: PropTypes.number.isRequired,
};

export const StaffOrManagerHomeDashboard = ({
  numNewReceived,
  numNewSendback,
}) => {
  return (
    <Fragment>
      <MDBox
        display="flex"
        sx={{ flexDirection: 'column' }}
        justifyContent="center"
        alignItems="center"
        px={1.5}
        component={NavLink}
        to="/new">
        <HomeCard
          badgeContent={numNewReceived}
          imgDarkSource={iconNewReceivedDark}
          imgLightSource={iconNewReceivedLight}
          text={intl.get('home_link_new_received_issues')}
        />
      </MDBox>

      <MDBox
        display="flex"
        sx={{ flexDirection: 'column' }}
        justifyContent="center"
        alignItems="center"
        px={1}
        component={NavLink}
        to="/sent">
        <HomeCard
          badgeContent={numNewSendback}
          imgDarkSource={iconNewSendbackDark}
          imgLightSource={iconNewSendbackLight}
          text={intl.get('home_link_new_sendback_issues')}
        />
      </MDBox>
    </Fragment>
  );
};

StaffOrManagerHomeDashboard.propTypes = propTypes;
