import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import MDTypography from 'components/MDTypography';

const propTypes = {
  organizationName: PropTypes.string.isRequired,
};

const ProjectBoardHeader = ({ organizationName }) => (
  <Fragment>
    <MDTypography data-testid="kanban-kenshin-title">
      {organizationName} -- {intl.get('kanban_text_task_management')}
    </MDTypography>
  </Fragment>
);

ProjectBoardHeader.propTypes = propTypes;

export default ProjectBoardHeader;
