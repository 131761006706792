import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import intl from 'react-intl-universal';

import { Container } from './styles';

const propTypes = {
  handleFiles: PropTypes.func.isRequired,
};

const DashboardNewDropZone = ({ handleFiles }) => {
  const handleOnDrop = (files) => {
    handleFiles(files);
  };

  return (
    <Dropzone onDrop={handleOnDrop}>
      {({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} data-testid="file-input" />
            <Container>
              <p>{intl.get('new_text_dropzone_message')}</p>
            </Container>
          </div>
        );
      }}
    </Dropzone>
  );
};

DashboardNewDropZone.propTypes = propTypes;

export default DashboardNewDropZone;
