import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Tooltip, IconButton } from '@material-ui/core';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import useStyles from './styles';

const propTypes = {
  selectedRows: PropTypes.object.isRequired,
  setBatchEditModalIsOpen: PropTypes.func.isRequired,
  setSelectedIssueIndexes: PropTypes.func.isRequired,
};

/**
 *  This is an edit button on the toolbar of the table when user multi-select rows;
 *  It sets the selected indexes; And then sets the Batch-Edit Modal window open;
 *
 *  This is the only thing it does; Nothing else;
 */
const CustomToolbarSelectBatchEdit = ({
  selectedRows,
  setBatchEditModalIsOpen,
  setSelectedIssueIndexes,
}) => {
  const classes = useStyles();

  const handleIconButtonClick = () => {
    const selectedIssueIndexes = selectedRows.data.map(
      (item) => item.dataIndex
    );
    setSelectedIssueIndexes(selectedIssueIndexes);
    setBatchEditModalIsOpen(true);
  };

  return (
    <Fragment>
      {/* <Tooltip
        className={classes.tooltip}
        // title={intl.get(
        //   'billing_for_received_cases_toolbar_select_tooltip_star'
        // )}
        // title={<h1 style={{ color: 'lightblue' }}>{'一括編集'}</h1>}
        title={<h1>{'一括編集'}</h1>}
        // title={"一括編集"}
      >
        <IconButton
          className={classes.iconButton}
          onClick={handleIconButtonClick}>
          <EditIcon />
        </IconButton>
      </Tooltip> */}
      <Button
        component="label"
        variant="contained"
        startIcon={<EditIcon />}
        onClick={handleIconButtonClick}>
        {intl.get('dashboard_received_history_table_button_edit')}
      </Button>
    </Fragment>
  );
};

CustomToolbarSelectBatchEdit.propTypes = propTypes;

export default withRouter(CustomToolbarSelectBatchEdit);
