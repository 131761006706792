import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import {
  InfoLabel,
  InfoValue,
  InfoValueSmall,
  InfoContent,
  InfoContainer,
  // InfoContainerTags,
} from './styles';
// import { makeTagString } from 'pages/Dashboard/New/utils';
import { IssuePropTypes } from 'shared/propTypes/issueType';
import {
  formatDateTimeConversational,
  readableJapaneseDateFormat,
} from 'shared/utils/dateTime';
// import { getUserFullName } from 'shared/utils/name';

import Status from 'pages/Dashboard/Kanban_Kenshin/IssueDetails/Status';
import Description from 'pages/Dashboard/Kanban_Kenshin/IssueDetails/Description';

const propTypes = {
  issue: PropTypes.shape(IssuePropTypes).isRequired,
  doctors: PropTypes.array,
  isClient: PropTypes.bool,
  isTaskManager: PropTypes.bool,
  setConfirmModalInfo: PropTypes.func,
  setConfirmModalUpdateData: PropTypes.func,
  setConfirmModalOpen: PropTypes.func,
};

const ProjectBoardIssueDetailsInfo = ({
  issue,
  doctors,
  isClient,
  isTaskManager,
  setConfirmModalInfo,
  setConfirmModalUpdateData,
  setConfirmModalOpen,
}) => {
  // save this for future;
  // const doctorIds = issue.cases.map((item) => item.doctorId).filter(Boolean);
  // const doctorsAssigned = doctors.filter((item) => doctorIds.includes(item.id));
  // const doctorNames = doctorsAssigned.map((doctor) =>
  //   getUserFullName(doctor.firstName, doctor.lastName)
  // );

  return (
    <Fragment>
      <InfoContent>
        <InfoContainer>
          <InfoLabel>{intl.get('issue_details_info_label_from')}</InfoLabel>
          <InfoValue>{issue.srcOrgName}</InfoValue>
        </InfoContainer>
        <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_created_time')}
          </InfoLabel>
          <InfoValueSmall>
            {/* {readableJapaneseDateFormat(issue.createdAt)} */}
            {issue.createdAt}
          </InfoValueSmall>
          <InfoValueSmall>
            {formatDateTimeConversational(issue.createdAt)}
          </InfoValueSmall>
        </InfoContainer>

        <InfoContainer>
          <Description issue={issue} />
        </InfoContainer>

        {/* <InfoContainer>
          <InfoLabel>
            {intl.get('issue_details_info_label_department')}
          </InfoLabel>
          <InfoValue>{issue.department}</InfoValue>
        </InfoContainer> */}
        {/* <InfoContainerTags>
          <InfoLabel>{intl.get('issue_details_text_tags')}</InfoLabel>
          <InfoValue>
            {intl.get('case_tag_value_section_1')}: {CTTags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_2')}: {MRITags.join(', ')}
          </InfoValue>
          <InfoValue>
            {intl.get('case_tag_value_section_3')}: {PhyExamTags.join(', ')}
          </InfoValue>
        </InfoContainerTags> */}
        {/* {renderTags()} */}

        {/* // save this for future;  */}
        {/* <InfoContainer>
          <InfoLabel>
            {'被分配到病例的医生'}
          </InfoLabel>
          <InfoValue>{doctorNames.join(', ')}</InfoValue>
        </InfoContainer> */}

        {isTaskManager === true && (
          <InfoContainer>
            <Status
              isClient={isClient}
              issue={issue}
              setConfirmModalInfo={setConfirmModalInfo}
              setConfirmModalUpdateData={setConfirmModalUpdateData}
              setConfirmModalOpen={setConfirmModalOpen}
            />
          </InfoContainer>
        )}
      </InfoContent>
    </Fragment>
  );
};

ProjectBoardIssueDetailsInfo.propTypes = propTypes;

export default ProjectBoardIssueDetailsInfo;
