import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import intl from 'react-intl-universal';

import { DropZoneContainer, DropZoneOverlay } from './styles';
import { generateDirectoryList } from 'pages/Dashboard/New/utils';
import { Grid } from '@material-ui/core';

const propTypes = {
  handleFiles: PropTypes.func.isRequired,
  handleDeleteFiles: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  darkMode: PropTypes.bool.isRequired,
};

const DashboardNewDropZone = ({
  handleFiles,
  files,
  handleDeleteFiles,
  darkMode,
}) => {
  const handleOnDrop = (files) => {
    handleFiles(files);
  };

  return (
    <Dropzone onDrop={handleOnDrop} noClick={true}>
      {({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} data-testid="file-input" />
            <DropZoneContainer containsFiles={files.length > 0}>
              <Grid container>
                {generateDirectoryList(files, handleDeleteFiles, darkMode)}
              </Grid>
              {files.length === 0 && (
                <DropZoneOverlay
                  darkMode={darkMode}
                  containsFiles={files.length > 0}>
                  {intl.get('new_text_dropzone_message')}
                </DropZoneOverlay>
              )}
            </DropZoneContainer>
          </div>
        );
      }}
    </Dropzone>
  );
};

DashboardNewDropZone.propTypes = propTypes;

export default DashboardNewDropZone;
