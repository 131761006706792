import React, { Fragment } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const chipBaseStyle = {
  margin: '1px',
  color: 'black',
  border: '1px solid black',
};

const chipColorStyle = {
  TO_BE_DIAGNOSED: {
    ...chipBaseStyle,
    backgroundColor: 'lightgrey',
    fontWeight: 'bold',
  },
  DIAGNOSED_UNKNOWN: {
    ...chipBaseStyle,
    backgroundColor: 'orange',
    fontWeight: 'bold',
  },
  DIAGNOSED_SICK: {
    ...chipBaseStyle,
    backgroundColor: 'red',
    fontWeight: 'bold',
  },
  DIAGNOSED_HEALTHY: {
    ...chipBaseStyle,
    backgroundColor: 'green',
    fontWeight: 'bold',
    color: 'white',
  },
};

const propTypes = {
  result: PropTypes.string,
};

const DiagnosticResultChips = ({ result }) => {
  // Function to determine chip style based on result
  const getChipStyle = (chipResult) => {
    if (result === chipResult) {
      return chipColorStyle[chipResult];
    }
    return { ...chipBaseStyle, opacity: 0.2, backgroundColor: 'transparent' };
  };

  return (
    <Stack spacing={1} alignItems="left">
      <Stack direction="row" spacing={0}>
        <Chip
          data-testid="chip-TO_BE_DIAGNOSED"
          label={intl.get('kenshin_case_diagnostic_result_to_be_diagnosed')}
          style={getChipStyle('TO_BE_DIAGNOSED')}
        />
        <Chip
          data-testid="chip-DIAGNOSED_UNKNOWN"
          label={intl.get('kenshin_case_diagnostic_result_diagnosed_unknown')}
          style={getChipStyle('DIAGNOSED_UNKNOWN')}
        />
        <Chip
          data-testid="chip-DIAGNOSED_SICK"
          label={intl.get('kenshin_case_diagnostic_result_diagnosed_sick')}
          style={getChipStyle('DIAGNOSED_SICK')}
        />
        <Chip
          data-testid="chip-DIAGNOSED_HEALTHY"
          label={intl.get('kenshin_case_diagnostic_result_diagnosed_healthy')}
          style={getChipStyle('DIAGNOSED_HEALTHY')}
        />
      </Stack>
    </Stack>
  );
};

DiagnosticResultChips.propTypes = propTypes;

export default DiagnosticResultChips;
