import React, { useState, Fragment } from 'react';
import intl from 'react-intl-universal';

import {
  Grid,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Box,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// components and parts
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

import { useMaterialUIController } from 'context';

import { FormLabel } from '@mui/material';

import useApi from 'shared/hooks/api';
import { PageLoader, PageError } from 'components';
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';

import dummyTagList from './constants/dummy-tags.json';

const propTypes = {
  tagListJson: PropTypes.object.isRequired,
  setTagListJson: PropTypes.func.isRequired,
  checkboxForm: PropTypes.array.isRequired,
  setCheckboxForm: PropTypes.func.isRequired,
  openCheckboxForm: PropTypes.bool.isRequired,
  setOpenCheckboxForm: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  dstOrgId: PropTypes.number.isRequired,
};

const DashboardNewTagCheckboxForm = ({
  tagListJson,
  setTagListJson,
  checkboxForm,
  setCheckboxForm,
  openCheckboxForm,
  setOpenCheckboxForm,
  organizationId,
  dstOrgId,
}) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  // TODO: Temp for debug, change it to
  setTagListJson(dummyTagList);

  // TODO: Code to get tagListJSON from backend server (now only reply a msg)
  // const [{ data, error }] = useApi.get(`${DOLPHIN_API.ISSUES}/new`, {
  //   params: { organizationId: organizationId, dstOrgId: dstOrgId },
  // });

  const [{ data, error }] = useApi.get(`${DOLPHIN_API.CHECK_ITMES}`, {
    params: {
      organizationId: organizationId,
      srcOrgId: organizationId,
      dstOrgId: dstOrgId,
    },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const handleBoxClick = (event) => {
    const index = event.target.id;
    const checked = event.target.checked;
    const newCheckboxForm = [...checkboxForm];
    newCheckboxForm[index] = checked;
    setCheckboxForm(newCheckboxForm);
  };

  if (checkboxForm.length === 0) {
    if (tagListJson && tagListJson.tags) {
      setCheckboxForm(Array(tagListJson.tags.length).fill(false));
    }
  }

  const renderTags = () => {
    if (tagListJson && tagListJson.tags) {
      // const tagList = JSON.parse(tagListJson);
      const tagList = tagListJson;
      const tags = tagList.tags;

      return (
        <Grid spacing={3} container>
          <Grid container spacing={3}>
            <Grid xs={1} item></Grid>
            <Grid xs={10} item>
              <FormControl component="fieldset" fullwidth>
                {tags.map((tag, index) => {
                  return (
                    <FormGroup key={index}>
                      <FormControlLabel
                        style={{ color: darkMode ? 'white' : 'black' }}
                        control={
                          <Checkbox
                            icon={
                              <CheckBoxOutlineBlankIcon
                                style={{ fill: '#66ccff' }}
                              />
                            }
                            checked={checkboxForm[index]}
                            onChange={handleBoxClick}
                            name={tag.labelText}
                            id={index}
                          />
                        }
                        label={tag.labelText}
                      />
                    </FormGroup>
                  );
                })}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <Card
      data-testid="new-issue-tags-modal-contents"
      sx={{
        width: 900,
        maxHeight: 1200,
        overflow: 'auto',
      }}>
      <MDBox py={3} px={3} mt={3} mx={3}>
        {renderTags()}
        <Grid container alignItems="flex-end">
          <Grid xs={12} item>
            <Box
              display="flex"
              justifyContent="center"
              sx={{ marginTop: '20px' }}>
              <MDButton
                color="info"
                style={{
                  color: 'white',
                  width: '10%',
                }}
                onClick={() => setOpenCheckboxForm(!openCheckboxForm)}>
                {intl.get('new_modal_tag_confirm')}
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

DashboardNewTagCheckboxForm.propTypes = propTypes;

export default DashboardNewTagCheckboxForm;
