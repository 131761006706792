import { List, ListItem, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import { useState } from 'react';
import SidenavListItem from 'components/SidenavListItem/SidenavListItem';
import { OrganizationProptypes } from 'shared/propTypes/organizationType';
import SidenavItemCollapse from 'components/SidenavItemCollapse/SidenavItemCollapse';
import pxToRem from 'assets/theme/functions/pxToRem';

export const UserProfile = ({
  userName,
  currentOrganization,
  organizationList,
  handleClickLogout,
  handleChangeCurrentOrganization,
}) => {
  const [openOrganizationCollapse, setOpenOrganizationCollapse] =
    useState(false);

  // ref: https://mui.com/material-ui/react-avatar/
  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  return (
    <ListItem sx={{ padding: pxToRem(10) }} data-testid="UserProfile">
      <Stack direction="row">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Centers horizontally
            alignItems: 'center', // Centers vertically
            marginLeft: '6px',
          }}>
          <Avatar
            // sx={{ paddingLeft: '13px' }}
            data-testid="UserProfile-avatar"
            {...stringAvatar(userName)}
            sx={{ height: '60px', width: '60px' }}
          />
        </Box>

        <List>
          <Box sx={{ paddingLeft: '2px' }}>
            <SidenavListItem name={userName} customPaddingLeft="2px" />
            <SidenavItemCollapse
              customPaddingLeft="2px"
              name={
                currentOrganization !== 'null'
                  ? currentOrganization
                  : intl.get('dashboard_button_no_organization')
              }
              active={openOrganizationCollapse}
              open={openOrganizationCollapse}
              onClick={() =>
                setOpenOrganizationCollapse(!openOrganizationCollapse)
              }>
              {organizationList &&
                organizationList.map((org, index) => (
                  <SidenavListItem
                    customPaddingLeft="6px"
                    key={org.id}
                    nested={true}
                    name={org.name}
                    isClickable={true}
                    onClick={() =>
                      handleChangeCurrentOrganization(org.name, org.id)
                    }
                  />
                ))}
            </SidenavItemCollapse>
            <SidenavListItem
              customPaddingLeft="2px"
              name={intl.get('dashboard_button_logout')}
              isClickable={true}
              onClick={handleClickLogout}
            />
          </Box>
        </List>
      </Stack>
    </ListItem>
  );
};

UserProfile.propTypes = {
  userName: PropTypes.string.isRequired,
  currentOrganization: PropTypes.string,
  organizationList: PropTypes.arrayOf(PropTypes.shape(OrganizationProptypes)),
  handleClickLogout: PropTypes.func,
  handleChangeCurrentOrganization: PropTypes.func,
};
