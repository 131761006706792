import React from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';

import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { getUserFullName } from 'shared/utils/name';
import { PageLoader, PageError } from 'components';

import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { DoctorHomeDashboard } from './Doctor';
import { StaffOrManagerHomeDashboard } from './StaffOrManager';
import GeneralHomepage from './General';
import { USER_DATA, UserType } from 'shared/constants/users';
import { DOLPHIN_API } from 'shared/constants/apis';
import {
  isCurrentUserPicAsDoctor,
  isCurrentUserTaskManager,
} from 'shared/utils/privilege';

const DashboardHome = () => {
  const isPicAsDoctor = isCurrentUserPicAsDoctor();
  const userType = localData.get(USER_DATA.USER_TYPE);
  const currentOrganizationId = localData.get(
    USER_DATA.CURRENT_ORGANIZATION_ID
  );

  const userFullName = getUserFullName();

  const [{ data, error }] = useApi.get(DOLPHIN_API.TODOS, {
    params: { organizationId: currentOrganizationId },
  });

  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { numNewInCharge, numNewAssigned, numNewReceived, numNewSendback } =
    data;

  return (
    <MDBox py={3} px={3} mt={3} mx={3}>
      <MDBox pl={1} mb={5} display="flex" sx={{ flexDirection: 'column' }}>
        <MDTypography variant="h4">
          {userFullName + intl.get('honorifics')}
        </MDTypography>
        <MDTypography variant="h2">
          {intl.get('home_text_welcome_back_new_design')}
        </MDTypography>
      </MDBox>
      <br />
      <br />
      <MDBox display="flex" alignItems="center" justifyContent="center">
        <MDBox
          px={3}
          mb={5}
          width="80%"
          display="flex"
          justifyContent="space-around"
          alignItems="center">
          {userType === UserType.DOCTOR ? (
            <DoctorHomeDashboard
              numberNewInCharge={data.numberNewInCharge}
              numNewAssigned={numNewAssigned}
            />
          ) : (
            <StaffOrManagerHomeDashboard
              numNewReceived={numNewReceived}
              numNewSendback={numNewSendback}
            />
          )}
        </MDBox>
      </MDBox>
    </MDBox>
  );
};

export default withRouter(DashboardHome);
