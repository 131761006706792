import styled, { css } from 'styled-components';

import { color, font } from 'shared/utils/styles';
import Icon from '../Icon';

export const StyledInput = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const InputElement = styled.input`
  height: 100%;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  min-height: 55px;
  border: 1px solid ${color.borderLightest};
  color: ${color.textDarkest};
  background-color: ${(props) => props.theme.palette.surface.main};
  transition: background 0.1s;
  ${font.regular}
  ${font.size(15)}
  ${(props) => props.hasIcon && 'padding: 0 50px;'}
  &:hover {
    background: ${color.backgroundLight};
  }
  &:focus {
    background: #fff;
    border: 1px solid ${color.borderInputFocus};
    box-shadow: 0 0 0 1px ${color.borderInputFocus};
  }
  ${(props) =>
    props.invalid &&
    css`
      &,
      &:focus {
        border: 1px solid ${color.danger};
        box-shadow: none;
      }
    `}
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 25px;
  font-weight: bold;
  pointer-events: none;
  color: ${color.textMedium};
`;
