import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';

// functions
import useApi from 'shared/hooks/api';
import localData from 'shared/utils/localData';
import { updateArrayItemById } from 'shared/utils/javascript';
import { getFirstDayPreviousMonth } from 'shared/utils/dateTime';

// constants
import { DOLPHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';
import { ISSUE_LIST_TYPE } from 'shared/constants/issueList';

// components and parts
import { PageLoader, PageError, Modal } from 'components';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { getSentTableColumns } from './SentTableColumns';
import IssueDetails from 'pages/Dashboard/Kanban/IssueDetails';
import { useMaterialUIController } from 'context';
import { getDatePickerTheme, getTablesTheme } from 'pages/parts/CustomThemes';
import { IssueStatusForClient } from 'shared/constants/issues';
import CustomSearchRender from 'components/CustomSearchRender';
import { CustomDatePicker } from 'pages/parts/DatePicker';
import CustomToolbarSelect from './CustomToolbarSelect';
import BatchEditModal from './BatchEditModal';
import { modalWidths } from 'shared/utils/styles';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
  type: PropTypes.string,
};

const DashboardSentHistory = ({ match, history, type }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const currentDate = new Date();
  const firstDayPreviousMonth = getFirstDayPreviousMonth(currentDate);
  const [timeRangeStart, setTimeRangeStart] = useState(firstDayPreviousMonth);
  const [timeRangeEnd, setTimeRangeEnd] = useState(currentDate);
  const [batchEditModalIsOpen, setBatchEditModalIsOpen] = useState(false);
  const [selectedIssueIndexes, setSelectedIssueIndexes] = useState([]);

  // TODO: [Privilege] different api for doctor or staff usage
  const [{ data, error, setLocalData }] = useApi.get(DOLPHIN_API.ISSUES_SENT, {
    params: {
      organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
      type: type,
      timeRangeStart: timeRangeStart,
      timeRangeEnd: timeRangeEnd,
    },
  });
  if (!data) return <PageLoader />;
  if (error) return <PageError />;

  const { sentIssues, doctors } = data;

  const updateLocalProjectIssues = (issueId, updatedFields) => {
    setLocalData((currentData) => ({
      sentIssues: updateArrayItemById(
        currentData.sentIssues,
        issueId,
        updatedFields
      ),
      doctors: doctors,
    }));
  };

  const handleOpenIssueDetails = (issueId) => {
    history.push(`${match.path}/issues/${issueId}`);
    // history.push(`/issues/${issueId}`);
  };

  const closeBatchEditModal = () => {
    setBatchEditModalIsOpen(false);
  };

  sentIssues.sort((issue1, issue2) => issue2.id - issue1.id);

  const tableData = sentIssues.map((issue) => {
    return [
      issue.title,
      issue.patientName,
      IssueStatusForClient[issue.status],
      issue.state,
      issue.createdAt,
      issue.deadline,
      issue.dstOrgName,
      issue.id,
    ];
  });

  const columns = getSentTableColumns(handleOpenIssueDetails);

  const options = {
    print: false,
    download: false,
    selectableRowsHideCheckboxes: false,
    selectableRowsHeader: false, // hide the "select all" button since it is confusing: it selects all instead of just the current page;
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    responsive: 'standard',
    tableBodyMaxHeight: '950px',
    rowsPerPageOptions: [10, 20],
    // sortOrder: {
    //   name: 'order_time',
    //   direction: 'asc',
    // },
    customSearchRender: (searchText, handleSearch, hideSearch, options) => {
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={handleSearch}
          onHide={hideSearch}
          options={options}
          tableTitle={
            type === ISSUE_LIST_TYPE.ASSIGNMNET
              ? intl.get('case_list_text_in_charge_table_title')
              : intl.get('case_list_text_sent_table_title')
          }
        />
      );
    },

    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <CustomToolbarSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          setBatchEditModalIsOpen={setBatchEditModalIsOpen}
          setSelectedIssueIndexes={setSelectedIssueIndexes}
        />
      );
    },

    rowsSelected: selectedIssueIndexes,

    textLabels: {
      body: {
        noMatch: intl.get('share_muitable_text_label_body_no_match'),
        toolTip: intl.get('share_muitable_text_label_body_tool_tip'),
      },
      pagination: {
        next: intl.get('share_muitable_text_label_pagination_next'),
        previous: intl.get('share_muitable_text_label_pagination_previous'),
        rowsPerPage: intl.get(
          'share_muitable_text_label_pagination_rows_per_page'
        ),
        displayRows: intl.get(
          'share_muitable_text_label_pagination_display_rows'
        ),
      },
      toolbar: {
        search: intl.get('share_muitable_text_label_toolbar_search'),
        downloadCsv: intl.get('share_muitable_text_label_toolbar_download_csv'),
        print: intl.get('share_muitable_text_label_toolbar_print'),
        viewColumns: intl.get('share_muitable_text_label_toolbar_view_columns'),
        filterTable: intl.get('share_muitable_text_label_toolbar_filter_table'),
      },
      filter: {
        all: intl.get('share_muitable_text_label_filter_all'),
        title: intl.get('share_muitable_text_label_filter_title'),
        reset: intl.get('share_muitable_text_label_filter_reset'),
      },
      viewColumns: {
        title: intl.get('share_muitable_text_label_view_columns_title'),
        titleAria: intl.get(
          'share_muitable_text_label_view_columns_title_aria'
        ),
      },
      selectedRows: {
        text: intl.get('share_muitable_text_label_selected_rows_text'),
        delete: intl.get('share_muitable_text_label_selected_rows_delete'),
        deleteAria: intl.get(
          'share_muitable_text_label_selected_rows_delete_aria'
        ),
      },
    },
  };

  // TODO: [Privilege]
  return (
    <Fragment>
      <div data-testid="page-width-control" style={{ minWidth: '1680px' }}>
        <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
          <Grid item>
            <MDBox ml={2}>
              <MDTypography
                variant="h6"
                gutterBottom
                style={{
                  fontWeight: 'bold',
                  marginTop: '16px',
                  marginRight: '18px',
                  textAlign: 'right',
                  alignSelf: 'stretch',
                }}>
                {intl.get('dashboard_sent_history_time_selection_title')}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2}>
            <ThemeProvider theme={getDatePickerTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeStart}
                setSelectedDate={setTimeRangeStart}
                labelText={intl.get(
                  'dashboard_sent_history_time_selection_from'
                )}
              />
            </ThemeProvider>
          </Grid>
          <Grid item>
            <MDBox ml={2}></MDBox>
          </Grid>
          <Grid item xs={2}>
            <ThemeProvider theme={getDatePickerTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeEnd}
                setSelectedDate={setTimeRangeEnd}
                labelText={intl.get('dashboard_sent_history_time_selection_to')}
                minDate={timeRangeStart}
              />
            </ThemeProvider>
          </Grid>
        </Grid>

        <Card sx={{ margin: 2, marginTop: 3, overflow: 'visible' }}>
          <MDBox>
            <ThemeProvider theme={getTablesTheme(darkMode)}>
              <MUIDataTable
                title={
                  type === ISSUE_LIST_TYPE.ASSIGNMNET
                    ? intl.get('case_list_text_in_charge_table_title')
                    : intl.get('case_list_text_sent_table_title')
                }
                data={tableData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </MDBox>
        </Card>
        {/* </MDBox> */}
        <Route
          path={`${match.path}/issues/:issueId`}
          render={(routeProps) => (
            <Modal
              isOpen
              testid="modal:issue-details"
              width={modalWidths.xxl}
              minWidth={modalWidths.xl}
              withCloseIcon={false}
              onClose={() => history.push(match.url)}
              renderContent={(modal) => (
                <IssueDetails
                  issueId={routeProps.match.params.issueId}
                  trigger={'byClick'}
                  // projectUsers={users}
                  doctors={doctors}
                  updateLocalProjectIssues={updateLocalProjectIssues}
                  modalClose={modal.close}
                />
              )}
            />
          )}
        />

        <Route
          path={`${match.path}/issues/:issueId/previous-issue/:prevIssueId`}
          render={(routeProps) => (
            <Modal
              isPrevIssue={true}
              isOpen
              testid="modal:issue-details-previous-issue"
              width={modalWidths.xxl}
              minWidth={modalWidths.xl}
              withCloseIcon={false}
              onClose={() => history.goBack()}
              renderContent={(modal) => (
                <IssueDetails
                  issueId={routeProps.match.params.prevIssueId}
                  trigger={'byClick'}
                  doctors={doctors}
                  updateLocalProjectIssues={updateLocalProjectIssues}
                  modalClose={modal.close}
                  isPrevIssue={true}
                />
              )}
            />
          )}
        />

        {selectedIssueIndexes.length > 0 && batchEditModalIsOpen && (
          <BatchEditModal
            isOpened={batchEditModalIsOpen}
            closeModal={closeBatchEditModal}
            selectedIssueIndexes={selectedIssueIndexes}
            setSelectedIssueIndexes={setSelectedIssueIndexes}
            issues={sentIssues}
            doctors={doctors}
            updateLocalProjectIssues={updateLocalProjectIssues}
            handleOpenIssueDetails={handleOpenIssueDetails}
          />
        )}
      </div>
    </Fragment>
  );
};

DashboardSentHistory.propTypes = propTypes;

export default withRouter(DashboardSentHistory);
