import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';

export default styled(InputBase)(({ theme }) => {
  const { palette } = theme;

  const { surface } = palette;

  return {
    'label + &': {
      marginTop: theme.spacing(2),
    },
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-input': {
      borderRadius: 15,
      position: 'relative',
      backgroundColor: surface.main,
      border: '1px solid',
      borderColor: surface.main,
      fontSize: 16,
      padding: '10px 12px',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        // TODO We need to figure out the focus color
        borderColor: theme.palette.primary.main,
      },
    },
  };
});
