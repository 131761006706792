import styled from 'styled-components';

import { color } from 'shared/utils/styles';

export const StyledFilterSelect = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 6px 10px 6px;
  border: 1px solid ${color.borderLightest};
  min-width: 200px;
  min-height: 55px;
  vertical-align: middle;
  background-color: ${(props) => props.theme.palette.surface.main};
`;
