import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';
import Button from 'components/Button';
import { font, mixin } from 'shared/utils/styles';

export const TopActions = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 21px 18px 0;
`;

export const Title = styled.div`
  padding: 20px 0 6px;
  ${font.size(16)}
  ${font.medium}
`;

export const Content = styled.div`
  display: flex;
  padding: 0 30px 30px;
`;

export const Left = styled.div`
  width: 100%;
  padding: 0 30px;
`;

// export const Actions = styled.div`
//   display: flex;
//   padding-top: 12px;
//   & > button {
//     margin-right: 6px;
//   }
// `;

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 30px;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  ${mixin.boxShadowMedium}
  min-width: 120px;
  margin: 0 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  & > button {
    margin-right: 6px;
  }
`;

export const PrivilegeDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SwitchArea = styled.div`
  display: flex;
  justify-content: space-between;
  // padding-top: 12px;
  & > button {
    margin-right: 6px;
  }
`;

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
