import styled from 'styled-components';

import { color, font, mixin } from 'shared/utils/styles';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  min-height: 800px;
  max-height: 90vh;
  width: 25%;
  min-width: 210px;
  border-radius: 6px;
  background: ${(props) => props.theme.palette.surface.main};
`;

export const Title = styled.div`
  padding: 13px 10px 17px;
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.text.main};
  margin: auto;
  ${font.size(12.5)};
  ${mixin.truncateText}
`;

export const IssuesCount = styled.span`
  text-transform: lowercase;
  ${font.size(13)};
`;

export const Issues = styled.div`
  height: 100%;
  padding: 0 5px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  ${mixin.simpleCustomScrollbar}
`;

export const UpdateText = styled.div`
  ${font.size(14)};
  color: ${(props) => props.theme.palette.text.main};
  padding-bottom: 10px;
`;

export const ExtraItemsBlock = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
`;
