import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { color, font, mixin } from 'shared/utils/styles';
import { Avatar } from 'components';

export const IssueTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const IssueProperty = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px 0;
`;

export const IssueTags = styled.div`
  padding: 7px 0;
`;

export const IssueLink = styled(Link)`
  display: block;
  margin-bottom: 5px;
  min-width: 200px;
`;

export const Issue = styled.div`
  opacity: ${(props) => (props.status ? 0.3 : 1)};
  padding: 10px;
  border-radius: 3px;
  color: ${(props) => props.theme.palette.text.main};
  background: ${(props) => props.theme.palette.background.default};
  box-shadow: 0px 1px 2px 0px rgba(9, 30, 66, 0.25);
  border: solid 4px transparent;
  border-left-color: ${(props) => props.color};
  transition: background 0.1s;
  ${mixin.clickable}
  @media (max-width: 1100px) {
    padding: 10px 8px;
  }
  // &:hover {
  //   background: ${color.backgroundLight};
  // }
  ${(props) =>
    props.status &&
    css`
      filter: contrast(50%);
    `}

  ${(props) =>
    props.isBeingDragged &&
    css`
      transform: rotate(3deg);
      box-shadow: 5px 10px 30px 0px rgba(9, 30, 66, 0.15);
    `}
`;

export const Title = styled.p`
  padding-bottom: 11px;
  ${font.size(15)}
  @media (max-width: 1100px) {
    ${font.size(14.5)}
  }
`;

// Is used in the kanban item template
// dolphin/dolphin/src/pages/Dashboard/Kanban/Lists/List/Issue/index.jsx
export const KanbanItemTitle = styled.div`
  padding-bottom: 1px;
  ${font.size(15)}
  @media (max-width: 1100px) {
    ${font.size(14.5)}
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Assignees = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-left: 2px;
`;

export const AssigneeAvatar = styled(Avatar)`
  margin-left: -2px;
  box-shadow: 0 0 0 2px #fff;
`;
