import React, { Fragment, useState, useRef, useEffect } from 'react';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { useLatest } from 'ahooks';

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

// import intl from 'react-intl-universal';
import { ThemeProvider } from '@mui/material/styles';
import { Grid } from '@material-ui/core';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';

// functions
import useApi from 'shared/hooks/api';
import api from 'shared/utils/api';
import localData from 'shared/utils/localData';
// import { updateArrayItemById } from 'shared/utils/javascript';
import { getFirstDayPreviousMonth } from 'shared/utils/dateTime';

// constants
import { KENSHIN_API } from 'shared/constants/apis';
import { USER_DATA } from 'shared/constants/users';
// import { IssueStatusKenshin } from 'shared/constants/kenshin/issues';
import { MatchPropTypes } from 'shared/propTypes/matchType';
import { HistoryProptypes } from 'shared/propTypes/historyType';

// components and parts
import { PageLoader, PageError, Modal } from 'components';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useMaterialUIController } from 'context';
import { getDatePickerTheme, getTablesTheme } from 'pages/parts/CustomThemes';
import { CustomDatePicker } from 'pages/parts/DatePicker';

import Table from './Table';
import Viewer from './Viewer';
import Viewport from './Viewer/Viewport';
import { useSnackbar } from 'notistack';
import { modalWidths } from 'shared/utils/styles';

const propTypes = {
  match: PropTypes.shape(MatchPropTypes),
  history: PropTypes.shape(HistoryProptypes),
};

const DashboardReceivedAssignmentKenshin = ({ match, history }) => {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const currentDate = new Date();
  const firstDayPreviousMonth = getFirstDayPreviousMonth(currentDate);
  const [timeRangeStart, setTimeRangeStart] = useState(firstDayPreviousMonth);
  const [timeRangeEnd, setTimeRangeEnd] = useState(currentDate);

  // For Viewer
  const [currentIndex, setCurrentIndex] = useState(-1); // index in the assignments
  const [prevCase, setPrevCase] = useState(null);
  const [currCase, setCurrCase] = useState(null);
  const [succCase, setSuccCase] = useState(null);
  const [viewports, setViewports] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  // For Table
  const tableRef = useRef(null);
  // const [tablePagination, setTablePagination] = useState({  // da: comment out since they are never used;
  //   pageIndex: 0,
  //   pageSize: 10,
  // });

  const [tablePageIndex, setTablePageIndex] = useState(0);
  const [tablePageSize, setTablePageSize] = useState(10);
  const [tableColumnFilters, setTableColumnFilters] = useState([]);
  const [myAssignments, setMyAssignments] = useState([]);
  const [selectedIndice, setSelectedIndice] = useState([]);
  const [loadedKenshin, setLoadedKenshin] = useState(false);
  const latestMyAssignments = useLatest(myAssignments);
  const latestSelectedIndice = useLatest(selectedIndice);

  // ! Keep the following code temperoraily please
  // const [{ data, error, setLocalData }] = useApi.get(
  //   KENSHIN_API.ASSIGNMENT_RECEIVED,
  //   {
  //     baseURL: process.env.REACT_APP_KENSHIN_API_URL,
  //     params: {
  //       userId: localData.get(USER_DATA.USER_ID),
  //       organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
  //       username: localData.get(USER_DATA.USERNAME),
  //       timeRangeStart: timeRangeStart,
  //       timeRangeEnd: timeRangeEnd,
  //     },
  //   }
  // );

  // if (!data) return <PageLoader />;
  // if (error) return <PageError />;

  useEffect(() => {
    setLoadedKenshin(false);
    const kenshinApiVariables = {
      baseURL: process.env.REACT_APP_KENSHIN_API_URL,
      params: {
        userId: localData.get(USER_DATA.USER_ID),
        organizationId: localData.get(USER_DATA.CURRENT_ORGANIZATION_ID),
        username: localData.get(USER_DATA.USERNAME),
        timeRangeStart: timeRangeStart,
        timeRangeEnd: timeRangeEnd,
      },
    };

    api
      .get(KENSHIN_API.ASSIGNMENT_RECEIVED, kenshinApiVariables)
      .then(async (data) => {
        const { assignments } = data;
        assignments.sort(
          (assignment1, assignment2) => assignment2.id - assignment1.id
        );
        const myAssignments_ = assignments.map((assignment, index) => {
          return {
            ...assignment,
            index: index,
          };
        });
        const indice = [...Array(myAssignments_.length).keys()];
        setMyAssignments(myAssignments_);
        setSelectedIndice(indice);
        setLoadedKenshin(true);
      })
      .catch((error) => {
        return <PageError />;
      });
  }, [timeRangeStart, timeRangeEnd]);

  const createViewport = (index) => {
    if (index < 0 || index >= latestMyAssignments.current.length) {
      return null;
    }
    // const case_ = myAssignments[index];
    const case_ = latestMyAssignments.current[index];
    return <Viewport dicomFilePaths={case_.webUrl} />;
  };

  const updateLocalAssignments = (index, updatedFields) => {
    let tempAssignments = [...myAssignments];
    const { diagnosis, diagnosticNote } = updatedFields;
    tempAssignments[index].diagnosis = diagnosis;
    tempAssignments[index].diagnosticNote = diagnosticNote;
    setMyAssignments((currentState) => [...tempAssignments]);
  };

  // New version of functions

  const getIndexFromCaseId = (caseId) => {
    const index = myAssignments.findIndex(
      (myAssignment) => myAssignment.id === caseId
    );
  };

  const initViewport = (currIndex, prevIndex, nextIndex) => {
    const currViewport = createViewport(currIndex);
    const prevViewport = createViewport(prevIndex);
    const nextViewport = createViewport(nextIndex);
    setViewports({
      prevViewport: prevViewport,
      currViewport: currViewport,
      nextViewport: nextViewport,
    });
  };

  const calculatePrevIndex = (index) => {
    // const prevIndex = index - 1;
    // if (prevIndex < 0) {
    //   return -1;
    // }
    // return prevIndex; // TODO: according to the mui table's filter
    const currLoc = latestSelectedIndice.current.indexOf(index);
    if (currLoc - 1 < 0) {
      return -1;
    }
    return latestSelectedIndice.current[currLoc - 1];
  };

  const calculateNextIndex = (index) => {
    // const nextIndex = index + 1;
    // if (nextIndex >= myAssignments.length) {
    //   return -1;
    // }
    // return nextIndex; // TODO: according to the mui table's filter
    const currLoc = latestSelectedIndice.current.indexOf(index);
    if (currLoc + 1 >= latestSelectedIndice.current.length) {
      return -1;
    }
    return latestSelectedIndice.current[currLoc + 1];
  };

  const initViewer = (caseId, index) => {
    // TODO
    // get current index and setCurrentIndex
    let currIndex;
    if (index) {
      currIndex = index;
    } else {
      currIndex = myAssignments.findIndex(
        (assignment) => assignment.id === caseId
      );
    }
    const prevIndex = calculatePrevIndex(currIndex);
    const nextIndex = calculateNextIndex(currIndex);
    setCurrentIndex(currIndex); // use currIndex to get

    // init viewport
    initViewport(currIndex, prevIndex, nextIndex);
  };

  const updatePageIndex = () => {
    if (tableRef.current) {
      const currentPageSize = tableRef.current.pagination.pageSize;
      const currentPageIndex = Math.floor(currentIndex / currentPageSize);

      setTablePageIndex(currentPageIndex);
      setTablePageSize(currentPageSize);
    }
  };

  const handleOpenViewer = (caseId, index) => {
    let selectedIndex;
    if (index === -1) {
      selectedIndex = 0;
    } else {
      selectedIndex = index;
    }
    // init Viewer
    initViewer(caseId, selectedIndex);

    history.push(`${match.path}/viewer/${caseId}`);
  };

  const handleGoToPrevCase = () => {
    // 0. Get new index and prev index
    const prevIndex = calculatePrevIndex(currentIndex);
    const nextIndex = calculateNextIndex(currentIndex);

    if (prevIndex === -1) {
      // 0. issue the first one
      enqueueSnackbar('最初のケースです', {
        variant: 'warning',
        autoHideDuration: 3000,
      });

      setCurrentIndex(currentIndex);
    }

    const newPrevIndex = calculatePrevIndex(prevIndex);
    const newCurrIndex = prevIndex;
    const newNextIndex = calculateNextIndex(newCurrIndex);

    // 1. Prepare new viewport and unmount old viewport
    const newCurrViewport = viewports.prevViewport;
    const newNextViewport = viewports.currViewport;
    const newPrevViewport = createViewport(newPrevIndex);
    // TODO: unmount viewport.nextViewport

    // 2. Prepare new case id
    const newCaseId = myAssignments[newCurrIndex].id;

    // 3. Set new viewports
    setViewports({
      prevViewport: newPrevViewport,
      currViewport: newCurrViewport,
      nextViewport: newNextViewport,
    });

    // 4. Set currentIndex
    setCurrentIndex(newCurrIndex);

    // 5. Push to new url
    history.push(`${match.path}/viewer/${newCaseId}`);
  };

  const handleGoToNextCase = () => {
    // TODO
    // 0. Get new index and prev index
    const prevIndex = calculatePrevIndex(currentIndex);
    const nextIndex = calculateNextIndex(currentIndex);

    if (nextIndex === -1) {
      // TODO:
      enqueueSnackbar('最後のケースです', {
        variant: 'warning',
        autoHideDuration: 3000,
      });
      setCurrentIndex(currentIndex);
    }

    const newNextIndex = calculateNextIndex(nextIndex);
    const newCurrIndex = nextIndex;
    const newPrevIndex = calculatePrevIndex(newCurrIndex);

    // 1. Prepare new viewport and unmount old viewport
    const newCurrViewport = viewports.nextViewport;
    const newPrevViewport = viewports.currViewport;
    const newNextViewport = createViewport(newNextIndex);
    // TODO: unmount viewport.prevViewport

    // 2. Prepare new case id
    const newCaseId = myAssignments[newCurrIndex].id;

    // 3. Set new viewports
    setViewports({
      prevViewport: newPrevViewport,
      currViewport: newCurrViewport,
      nextViewport: newNextViewport,
    });

    // 4. Set currentIndex
    setCurrentIndex(newCurrIndex);

    // 5. Push to new url
    history.push(`${match.path}/viewer/${newCaseId}`);
  };

  const handleCloseViewer = (directUrl) => {
    if (tableRef.current) {
      const currentPageSize = tableRef.current.pagination.pageSize;
      const currentPageIndex = Math.floor(currentIndex / currentPageSize);
      setTablePageIndex(currentPageIndex);
      setTablePageSize(currentPageSize);
      // tableRef.current.setPagination({
      //   //! Why is this needed? commenting this out will fix the filter bug, and does not break pagination;
      //   pageIndex: currentPageIndex,
      //   pageSize: currentPageSize,
      // });

      const filters = tableRef.current.columnFilters;
      setTableColumnFilters(filters);
    }

    history.push(directUrl);
  };

  return (
    <Fragment>
      <Grid container style={{ marginBottom: '10px', marginTop: '40px' }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} ml={2}>
            <MDBox ml={4}>
              <MDTypography
                variant="h6"
                gutterBottom
                style={{
                  fontWeight: 'bold',
                  marginTop: '16px',
                  marginRight: '18px',
                  textAlign: 'right',
                  alignSelf: 'stretch',
                }}>
                {intl.get(
                  'dashboard_received_assignment_kenshin_time_selection_title'
                )}
              </MDTypography>
            </MDBox>
            <ThemeProvider theme={getDatePickerTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeStart}
                setSelectedDate={setTimeRangeStart}
                labelText={intl.get(
                  'dashboard_received_assignment_kenshin_time_selection_from'
                )}
              />
            </ThemeProvider>
            <ThemeProvider theme={getDatePickerTheme(darkMode)}>
              <CustomDatePicker
                selectedDate={timeRangeEnd}
                setSelectedDate={setTimeRangeEnd}
                labelText={intl.get(
                  'dashboard_received_assignment_kenshin_time_selection_to'
                )}
                minDate={timeRangeStart}
              />
            </ThemeProvider>
          </Stack>
        </Grid>
      </Grid>

      <Card sx={{ margin: 2, marginTop: 3, overflow: 'visible' }}>
        <MDBox>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Table
              assignments={myAssignments}
              updateLocalAssignments={updateLocalAssignments}
              selectedIndice={selectedIndice}
              setSelectedIndice={setSelectedIndice}
              handleOpenViewer={handleOpenViewer}
              tablePageIndex={tablePageIndex}
              tablePageSize={tablePageSize}
              tableColumnFilters={tableColumnFilters}
              ref={tableRef}
            />
          </LocalizationProvider>
        </MDBox>
      </Card>

      <Route
        path={`${match.path}/viewer/:caseId`}
        render={(routeProps) => (
          <Modal
            isOpen
            testid="modal:assignment-viewer"
            width="100%"
            minWidth={modalWidths.md}
            withCloseIcon={false}
            onClose={() => handleCloseViewer(match.url)}
            renderContent={(modal) => (
              <Viewer
                assignments={myAssignments}
                updateLocalAssignments={updateLocalAssignments}
                caseId={parseInt(routeProps.match.params.caseId)}
                onClose={() => handleCloseViewer(match.url)}
                currentIndex={currentIndex}
                viewports={viewports}
                initViewer={initViewer}
                handleGoToPrevCase={handleGoToPrevCase}
                handleGoToNextCase={handleGoToNextCase}
              />
            )}
          />
        )}
      />
    </Fragment>
  );
};

DashboardReceivedAssignmentKenshin.propTypes = propTypes;

export default withRouter(DashboardReceivedAssignmentKenshin);
