import React, { Fragment, useState } from 'react';
import intl from 'react-intl-universal';
import PropTypes from 'prop-types';
import { Message, Actions, StyledButton } from 'components/ConfirmModal/Styles';
import { ALERT_MODAL_INFO } from '../constants';

const propTypes = {
  modalClose: PropTypes.func.isRequired,
  alertModalInfo: PropTypes.string.isRequired,
};

const AlertWindow = ({ modalClose, alertModalInfo }) => {
  const [isWorking] = useState(false);

  const generateAlertMessage = (alertModalInfo) => {
    if (alertModalInfo === ALERT_MODAL_INFO.INVALID_DESTINATION) {
      return intl.get('kanban_alert_popwindow_text_invalid_destination');
    } else if (alertModalInfo === ALERT_MODAL_INFO.MOVE_FORWARD) {
      return intl.get('kanban_alert_popwindow_text_move_with_warning');
    }
  };

  const alertMessage = generateAlertMessage(alertModalInfo);

  return (
    <Fragment>
      <Message data-testid="kanban-invalid-destination-alert-message">
        {alertMessage}
      </Message>
      <Actions>
        <StyledButton
          data-testid="kanban-alert-window-button"
          variant={'primary'}
          isWorking={isWorking}
          onClick={modalClose}>
          {intl.get('kanban_alert_popwindow_button_yes')}
        </StyledButton>
      </Actions>
    </Fragment>
  );
};

AlertWindow.propTypes = propTypes;

export default AlertWindow;
