function actionButtonText(theme, ownerState) {
  const { palette } = theme;
  const { color } = ownerState;

  const { white, dark } = palette;

  return {
    color: color === 'primary' ? dark.main : white.main,
  };
}

export { actionButtonText };
