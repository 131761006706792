import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border-width: 4px;
  border-radius: 4px;
  border-style: solid;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const DropZoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-height: 250px;
  max-height: 350px;
  overflow-y: scroll;
  border-width: 2px;
  border-radius: 15px;
  border-style: solid;
  border-style: ${(props) => (props.containsFiles ? 'solid' : 'dashed')};
  background-color: ${(props) => props.theme.palette.background.main};
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const DropZoneOverlay = styled.p`
  position: absolute;
  top: 45%;
  left: 20%;
  color: ${(props) =>
    props.darkMode
      ? props.containsFiles
        ? '#bdbdbd1c'
        : props.theme.palette.text.main
      : props.containsFiles
      ? '#e8e5e5'
      : props.theme.palette.text.main};
  z-index: -1;
`;
