import React from 'react';
import PropTypes from 'prop-types';
import { Card, Modal, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import MDBox from 'components/MDBox';
import SimpleStyledButton from './SimpleStyledButton';
import StyledLoadingButton from './SimpleStyledLoadingButton';
import { modalBorderRadius } from 'shared/utils/styles';

const propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  actionButtons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
      close: PropTypes.bool,
    })
  ),
  title: PropTypes.string,
  bodyText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

// Custom Backdrop component that ignores clicks
const CustomBackdrop = (props) => {
  return <Backdrop {...props} onClick={(e) => e.stopPropagation()} />;
};
CustomBackdrop.displayName = 'CustomBackdrop';

// MultiButton Modal Component that will show multiple buttons in a modal
export const MultiButtonModal = ({
  isOpened,
  onClose,
  actionButtons,
  title,
  bodyText,
  children,
}) => {
  return (
    <Modal
      data-testid="shared-confirm-modal"
      disableEscapeKeyDown
      open={isOpened}
      onClose={onClose}
      slots={{ backdrop: CustomBackdrop }} // clicking the backdrop will not close the modal;
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Card
        sx={{
          width: 'auto',
          minWidth: '400px',
          padding: '30px 40px',
          position: 'relative',
          zIndex: 2000,
          borderRadius: modalBorderRadius.sm,
        }}>
        <MDBox>
          <Typography
            data-testid="confirm-modal-title"
            id="modal-title"
            sx={{
              paddingBottom: '10px',
              lineHeight: 1.5,
              fontSize: '20px',
              fontFamily: 'CircularStdMedium',
              fontWeight: 'normal',
              color: 'rgba(0, 0, 0, 0.87)',
            }}>
            {title}
          </Typography>
          <Typography
            data-testid="confirm-modal-body"
            component="pre"
            sx={{
              paddingBottom: '30px',
              whiteSpace: 'pre-wrap',
              color: 'rgba(0, 0, 0, 0.7)',
              fontSize: '16px',
              fontFamily: 'CircularStdBook',
            }}>
            {bodyText}
          </Typography>

          {children && <div>{children}</div>}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              paddingTop: children ? '30px' : '0px', // the buttons should always be 30px away from the top element;
            }}>
            {actionButtons.map(
              (
                {
                  onClick,
                  variant,
                  label,
                  close,
                  isLoadingButton,
                  isLoading,
                  dataTestId,
                },
                index
              ) =>
                isLoadingButton === true ? (
                  <div key={index}>
                    <StyledLoadingButton
                      data-testid={dataTestId}
                      onClick={() => (close ? onClose() : onClick?.())}
                      loading={isLoading}
                      loadingPosition="start"
                      key={index}
                      variant={variant}>
                      {label}
                    </StyledLoadingButton>
                  </div>
                ) : (
                  <SimpleStyledButton
                    data-testid={dataTestId}
                    onClick={() => (close ? onClose() : onClick?.())}
                    key={index + 1}
                    variant={variant}>
                    {label}
                  </SimpleStyledButton>
                )
            )}
          </div>
        </MDBox>
      </Card>
    </Modal>
  );
};

MultiButtonModal.propTypes = propTypes;
