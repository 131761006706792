import { createTheme } from '@mui/material/styles';

export const getDatePickerTheme = (darkMode) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#90caf9' : '#1976d2', // Example primary color
      },
      background: {
        default: darkMode ? '#303030' : '#fafafa', // Dark mode background
      },
      text: {
        primary: darkMode ? '#fff' : '#000', // White text in dark mode, black in light mode
      },
    },
  });

export const getTablesTheme = (darkMode) =>
  createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      background: {
        paper: darkMode ? '#1f283e' : '#ffffff', // Set the paper background color
      },
    },
    overrides: {
      MUIDataTable: {
        root: {
          backgroundColor: darkMode ? '#1f283e' : '#ffffff', // Set table background color
        },
        paper: {
          backgroundColor: darkMode ? '#1f283e' : '#ffffff', // Set table paper color
        },
      },
      MuiTableCell: {
        root: {
          '&.MuiTableCell-head': {
            // Targeting the table header cells
            backgroundColor: darkMode ? '#1f283e' : '#f5f5f5',
            color: darkMode ? '#ffffff' : '#000000',
            backgroundImage: darkMode
              ? 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))'
              : 'none',
          },
        },
        body: {
          backgroundColor: darkMode ? '#1f283e' : '#ffffff', // Body cell background color
          color: darkMode ? '#ffffff' : '#000000', // Body cell text color
        },
      },

      MuiTableRow: {
        head: {
          backgroundColor: darkMode ? '#1f283e' : '#f5f5f5', // Header row background color
        },
      },
      MuiButton: {
        text: {
          color: darkMode ? '#ffffff' : '#000000', // Button text color
        },
      },
    },
  });

export const treeItemTheme = createTheme({
  components: {
    // Name of the component
    MuiTreeView: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: 'black',
          backgroundColor: '#ffffff',
        },
      },
    },

    MuiTreeItem: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: 'black',
          backgroundColor: '#ffffff',
        },
      },
    },
  },
});
