/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from 'react';

// react-router-dom components
import { useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setLayout } from 'context';
import SideAds from 'components/SideAds';
import { Grid } from '@material-ui/core';

function DashboardLayout({ children, openDrawer }) {
  // Need to keep the controller here
  const [controller, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, 'dashboard');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <MDBox
      data-testid="dashboard-layout-box"
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,
        // position: 'relative',
        [breakpoints.up('xs')]: {
          marginTop: pxToRem(75),
          marginLeft: openDrawer ? pxToRem(274) : pxToRem(16),
          marginRight: pxToRem(16),
          transition: transitions.create(['margin-left', 'margin-right'], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}>
      <Grid
        data-testid="dashboard-layout-grid-container"
        container
        direction="row"
        alignItems="stretch"
        justifyContent="center">
        <Grid data-testid="dashboard-layout-grid-left" item xs={11}>
          {children}
        </Grid>
        <Grid data-testid="dashboard-layout-grid-right" item xs={1}>
          {/* <SideAds /> */}
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  openDrawer: PropTypes.bool,
};

export default DashboardLayout;
