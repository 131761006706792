import { useState, useEffect, Fragment } from 'react';
import intl from 'react-intl-universal';
import { useSnackbar } from 'notistack';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ConstructionIcon from '@mui/icons-material/Construction';
import ErrorIcon from '@mui/icons-material/Error';
import pxToRem from 'assets/theme/functions/pxToRem';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import MDProgress from 'components/MDProgress';
// import { dummyMissions } from './DummyData'; //! keep it in case needed;

import { formatBytes, calculateRemainingTime } from 'shared/utils/misc';
import { useMaterialUIController } from 'context';
import {
  useUploadMissionsContextController,
  removeMissionFromContext,
  abortUpload,
} from 'context/NewIssueContext';
import UploadMissionCard from './Card';
import { getBadge, generateMissionDetailsModalContents } from './util';
import { MISSION, STATUS } from 'shared/constants/uploadAttachments';

const UploadMissionsMenu = () => {
  // Contexts
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [controllerUploadMissions, dispatchUploadMissions] =
    useUploadMissionsContextController();
  // States
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const [detailModalIsOpen, setDetailModalIsOpen] = useState(false);
  const [selectedMission, setSelectedMission] = useState({});

  const [abortUploadWarningModalIsOpen, setAbortUploadWarningModalIsOpen] =
    useState(false);
  const [missionIDToBeAborted, setMissionIDToBeAborted] = useState('');

  const missions = controllerUploadMissions.missions;
  //! When want to use dummyMissions
  // const missions = dummyMissions;

  const numMissions = Object.values(missions).length;
  const missionsNotDoneYet = Object.values(missions).filter(
    (x) => x.status !== STATUS.PROCESS_COMPLETE
  );
  const numMissionsNotDoneYet = missionsNotDoneYet.length;
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newIssueIdentifierId]);

  const alertUser = (e) => {
    const ongoings = Object.values(controllerUploadMissions.missions).filter(
      (x) => x.status === STATUS.UPLOADING || x.status === STATUS.PROCESSING
    );
    const numOngoing = ongoings.length;
    if (numOngoing > 0) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  useEffect(() => {
    if (controllerUploadMissions.newIssueIdentifierId) {
      const missionId = controllerUploadMissions.newIssueIdentifierId;
      const mission = controllerUploadMissions.missions[missionId];
      setDetailModalIsOpen(true);
      setSelectedMission(mission);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newIssueIdentifierId]);

  useEffect(() => {
    if (controllerUploadMissions.newAttachmentsMissionId) {
      const missionId = controllerUploadMissions.newAttachmentsMissionId;
      const mission = controllerUploadMissions.missions[missionId];
      setDetailModalIsOpen(true);
      setSelectedMission(mission);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newAttachmentsMissionId]);

  useEffect(() => {
    if (anchorEl) {
      setMenuOpen(true);
    }
  }, [anchorEl]);

  useEffect(() => {
    if (controllerUploadMissions.newCompletedIssue) {
      enqueueSnackbar(intl.get('upload_mission_snackbar_alert_done'), {
        variant: 'success',
        autoHideDuration: 3000,
      });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controllerUploadMissions.newCompletedIssue]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  const handleMenuItemClick = (missionId) => {
    setSelectedMission(missions[missionId]);
    setDetailModalIsOpen(true);
  };

  const handleMissionAbortWarningModalConfirm = () => {
    abortUpload(dispatchUploadMissions, { missionId: missionIDToBeAborted });
    setAbortUploadWarningModalIsOpen(false);
  };

  const handleMissionAbortWarningModalCancel = () => {
    setAbortUploadWarningModalIsOpen(false);
  };

  const handleDetailModalClose = () => {
    // if mission is already done, remove it from context;
    if (selectedMission.status === STATUS.PROCESS_COMPLETE) {
      removeMissionFromContext(dispatchUploadMissions, {
        missionId: selectedMission.issueIdentifierId,
      });
    }

    setSelectedMission({});
    setDetailModalIsOpen(false);
  };

  const setMissionToBeRemovedFromList = (missionId) => {
    removeMissionFromContext(dispatchUploadMissions, {
      missionId: missionId,
    });
  };

  const renderMenuItems = () => {
    const missionItemsList = Object.entries(missions).map(
      ([key, value], index) => {
        const mission = value;
        const id =
          mission.type === MISSION.ISSUE
            ? mission.issueIdentifierId
            : mission.missionId;
        return (
          <MenuItem
            key={index}
            value={id}
            sx={{ px: 0.5 }}
            onClick={() => handleMenuItemClick(id)}>
            <UploadMissionCard
              mission={mission}
              setAbortUploadWarningModalIsOpen={
                setAbortUploadWarningModalIsOpen
              }
              setMissionIDToBeAborted={setMissionIDToBeAborted}
              setMissionToBeRemoved={setMissionToBeRemovedFromList}
            />
          </MenuItem>
        );
      }
    );

    return missionItemsList;
  };

  // Render the upload missions menu bottom corner
  const renderMenu = () => (
    <Menu
      id="upload-mission-menu"
      open={menuOpen}
      onClick={handleMenuClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          width: 500,
          maxWidth: '100%',
        },
      }}
      disableScrollLock={true}
      disableEnforceFocus={true}>
      <MenuList>{renderMenuItems()}</MenuList>
    </Menu>
  );

  const handleAbortButtonClick = (e) => {
    e.stopPropagation();
    const id =
      selectedMission.type === MISSION.ISSUE
        ? selectedMission.issueIdentifierId
        : selectedMission.missionId;
    setMissionIDToBeAborted(id);
    setAbortUploadWarningModalIsOpen(true);
  };

  if (numMissions === 0) {
    return <></>;
  }
  return (
    <Fragment>
      <MDButton
        right="200rem"
        bottom="200rem"
        variant="gradient"
        color="info"
        style={{
          color: 'white',
          fontSize: pxToRem(18),
          width: '400px',
          marginLeft: '25%',
          display: 'flex',
          position: 'fixed',
          bottom: 15,
          right: 30,
        }}
        onClick={handleMenuClick}>
        {`${numMissionsNotDoneYet} ${intl.get('upload_mission_menu_bar_text')}`}
      </MDButton>

      {renderMenu()}

      {/* Modal of misson details*/}
      {/* Irregular */}
      <Modal
        data-testid="upload-mission-popup-window"
        open={detailModalIsOpen}
        onClose={handleDetailModalClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Card sx={{ maxHeight: '70%', width: '60%', margin: 'auto' }}>
          <Grid container justifyContent="start">
            <Grid item>
              <MDBox
                mt={3}
                mb={2}
                mx={6}
                display="flex"
                alignItems="center"
                data-testid="modal-upload-mission-status">
                {selectedMission.status !== STATUS.UPLOADING &&
                  getBadge(selectedMission.status, 'lg')}
              </MDBox>
            </Grid>
          </Grid>
          <MDBox
            py={0}
            px={3}
            mt={0}
            mx={3}
            sx={{
              flexDirection: 'column',
              overflow: 'hidden',
              overflowY: 'scroll',
              maxHeight: '70%',
              overFlowY: 'auto',
            }}>
            {detailModalIsOpen &&
              generateMissionDetailsModalContents(selectedMission, darkMode)}
          </MDBox>
          <MDBox py={0} px={3} mt={0} mx={3}>
            {selectedMission.status === STATUS.UPLOADING ? (
              <MDBox mt={1} mb={1} data-testid="upload-section">
                <Stack direction="row" spacing={1} alignItems="center">
                  <MDTypography variant="body1" color="text" fontWeight="bold">
                    {`${intl.get('upload_mission_menu_item_uploading_file')}: `}
                  </MDTypography>
                  <MDTypography
                    variant="body1"
                    color="text"
                    style={{ whiteSpace: 'normal' }}
                    sx={{ width: '70%' }}>
                    {`${selectedMission.uploadingFileName}`}
                  </MDTypography>
                </Stack>
                <MDBox display="flex" alignItems="center">
                  <MDBox mt={0.5} width="100%">
                    <MDProgress
                      variant="gradient"
                      value={selectedMission.uploadingProgress}
                      color="info"
                    />
                  </MDBox>
                  <MDTypography variant="body3" ml={1}>
                    {`${Math.round(selectedMission.uploadingProgress)}%`}
                  </MDTypography>
                </MDBox>
                <MDBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center">
                  <MDTypography variant="body3">
                    {`${intl.get(
                      'upload_mission_menu_item_upload_speed'
                    )}: ${formatBytes(selectedMission.uploadingSpeed)}/Sec`}
                  </MDTypography>
                  <MDTypography variant="body3" mr={1}>
                    {`${intl.get(
                      'upload_mission_menu_item_upload_time_remaining'
                    )}: ${calculateRemainingTime(
                      selectedMission.batchTotalBytes,
                      selectedMission.uploadedBytes,
                      selectedMission.uploadingSpeed
                    )}`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            ) : (
              <></>
            )}

            {selectedMission.status === STATUS.PROCESSING ? (
              <MDBox mt={1} display="flex" alignItems="center">
                <HourglassTopIcon
                  color="info"
                  fontSize="medium"
                  sx={{ mx: 1 }}
                />
                <MDTypography fontWeight="bold" color="info">
                  {`${intl.get('upload_mission_menu_item_message_processing')}`}
                </MDTypography>
              </MDBox>
            ) : (
              <></>
            )}

            {selectedMission.status === STATUS.UPLOAD_FAILED ? (
              <MDBox mt={1} display="flex" alignItems="center">
                <ErrorIcon color="error" fontSize="medium" sx={{ mx: 1 }} />
                <MDTypography fontWeight="bold" color="error">
                  {`${intl.get(
                    'upload_mission_menu_item_message_upload_failed'
                  )}`}
                </MDTypography>
              </MDBox>
            ) : (
              <></>
            )}

            {selectedMission.status === STATUS.PROCESS_FAILED ? (
              <MDBox mt={1} display="flex" alignItems="center">
                <ConstructionIcon
                  color="error"
                  fontSize="medium"
                  sx={{ mx: 1 }}
                />
                <MDTypography fontWeight="bold" color="error">
                  {`${intl.get(
                    'upload_mission_menu_item_message_process_failed'
                  )}`}
                </MDTypography>
              </MDBox>
            ) : (
              <></>
            )}

            <Grid
              container
              spacing={1}
              justifyContent="space-around"
              alignItems="center">
              <Grid item>
                <MDButton
                  data-testid="upload_mission_menu_item_details_modal_close_button"
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '1px',
                    marginBottom: '15px',
                    width: '150px',
                  }}
                  onClick={handleDetailModalClose}>
                  {intl.get(
                    'upload_mission_menu_item_details_modal_close_button'
                  )}
                </MDButton>
              </Grid>
              {selectedMission.status === STATUS.UPLOADING && (
                <Grid item display="flex" justifyContent="flex-end">
                  <MDButton
                    data-testid="upload_mission_menu_item_details_modal_abort_button"
                    size="medium"
                    width="50px"
                    variant="contained"
                    color="error"
                    sx={{ my: 2, mx: 6 }}
                    onClick={handleAbortButtonClick}>
                    {intl.get('upload_mission_menu_item_abort')}
                  </MDButton>
                </Grid>
              )}
            </Grid>
          </MDBox>
        </Card>
      </Modal>

      {/* Modal delete confirmation */}
      <Modal
        data-testid="upload_mission_modal_delete_confirmation"
        disableBackdropClick
        disableEscapeKeyDown
        open={abortUploadWarningModalIsOpen}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'scroll',
        }}>
        <Card sx={{ margin: 4, marginTop: 3, width: 800 }}>
          <MDBox py={3} px={3} mt={3} mx={3}>
            <div>
              <MDTypography variant="h4" mb={1}>
                {intl.get('upload_mission_menu_item_abort_question')}
              </MDTypography>
              <MDTypography mb={4}>
                {intl.get('upload_mission_menu_item_abort_warning')}
              </MDTypography>
              <MDTypography mb={1}>
                {intl.get('upload_mission_menu_item_abort_warning_data')}
              </MDTypography>
            </div>

            <Grid
              container
              spacing={3}
              justify="center"
              style={{ marginTop: '20px' }}>
              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={handleMissionAbortWarningModalConfirm}>
                  {intl.get('upload_mission_menu_item_abort_confirm')}
                </MDButton>
              </Grid>

              <Grid xs={6} item>
                <MDButton
                  variant="gradient"
                  color="info"
                  style={{
                    color: 'white',
                    fontSize: pxToRem(18),
                    marginTop: '5px',
                    marginBottom: '15px',
                    width: '50%',
                    marginLeft: '25%',
                  }}
                  onClick={handleMissionAbortWarningModalCancel}>
                  {intl.get('upload_mission_menu_item_abort_cancel')}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Modal>
    </Fragment>
  );
};

export default UploadMissionsMenu;
