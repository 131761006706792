/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

// @mui material components
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import { sidenavHeader } from './Styles';

import { useMaterialUIController } from 'context';
import MDTypography from 'components/MDTypography';

function SidenavList({ title, children }) {
  const [controller] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  return (
    <List
      component="nav"
      sx={{
        px: 2,
        my: 0.3,
      }}>
      {title && (
        <ListItem>
          <MDTypography
            sx={(theme) =>
              sidenavHeader(theme, {
                transparentSidenav,
                miniSidenav,
                whiteSidenav,
                darkMode,
              })
            }>
            {title}
          </MDTypography>
        </ListItem>
      )}
      {children}
    </List>
  );
}

// Typechecking props for the SidenavItem
SidenavList.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default SidenavList;
