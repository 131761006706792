import React from 'react';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { withRouter } from 'react-router';
import { PDFViewer, Document, Page, Text } from '@react-pdf/renderer';

import ReportHeader from './ReportHeader';
import ReportNoAndDate from './ReportNoAndDate';
import ReportTitle from './ReportTitle';
import ReportInfo from './ReportInfo';
import ReportDescription from './ReportDescription';
import ReportMeasures from './ReportMeasures';
import ReportDiagnosis from './ReportDiagnosis';
import ReportKeyImages from './ReportKeyImages';
import ReportDateAndDoctors from './ReportDateAndDoctors';
import ReportFooter from './ReportFooter';

import { styles } from './styles';

const propTypes = {
  reportContent: PropTypes.object.isRequired,
};

const DashboardKanbanIssueDetailsReportsPreview = ({ reportContent }) => {
  const reportTitle = reportContent.fileName + '.pdf';

  return (
    <PDFViewer
      width="100%"
      height="100%"
      fileName={reportTitle}
      data-testid="reportPreviewModal-PDFViewer">
      <Document title={reportTitle}>
        <Page size="A4" style={styles.firstPage} wrap={true}>
          <ReportHeader />
          {/* <ReportNoAndDate
            reportNo={reportContent.issueTitle}
            reportDate={reportContent.reportDate}
          /> */}
          <ReportTitle />
          <ReportInfo reportContent={reportContent} />
          <ReportDescription reportContent={reportContent} />
          <ReportMeasures reportContent={reportContent} />
          <ReportDiagnosis reportContent={reportContent} />
          <ReportKeyImages reportContent={reportContent} />
          <ReportDateAndDoctors />
          {/* <ReportFooter /> */}
        </Page>
      </Document>
    </PDFViewer>
  );
};

DashboardKanbanIssueDetailsReportsPreview.propTypes = propTypes;

export default withRouter(DashboardKanbanIssueDetailsReportsPreview);
