import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import MDInputBootstrap from 'components/MDInputBootstrap';

function BootstrapFormField({ label, id, ...rest }) {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel shrink htmlFor={id} sx={{ marginLeft: '10px' }}>
        {label}
      </InputLabel>
      <MDInputBootstrap variant="standard" id={id} fullWidth {...rest} />
    </FormControl>
  );
}

BootstrapFormField.defaultProps = {
  label: '',
};

BootstrapFormField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default BootstrapFormField;
