function sidenavHeader(theme, ownerState) {
  const { palette, transitions, typography, functions } = theme;
  const { miniSidenav, active, transparentSidenav, whiteSidenav, darkMode } =
    ownerState;
  const { size, fontWeightRegular } = typography;

  const { white, dark, grey } = palette;
  const { pxToRem } = functions;
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: size.xs,
    padding: `${pxToRem(8)} ${pxToRem(16)}`,
    userSelect: 'none',
    position: 'relative',
    fontWeight: fontWeightRegular,
    color: grey[600],

    '& span': {
      color:
        ((transparentSidenav && !darkMode) || whiteSidenav) && !active
          ? dark.main
          : white.main,
      fontWeight: fontWeightRegular,
      fontSize: size.sm,
      opacity: miniSidenav ? 0 : 1,
      transition: transitions.create(['opacity', 'color'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    '&::before': {
      color:
        ((transparentSidenav && !darkMode) || whiteSidenav) && !active
          ? dark.main
          : white.main,
      fontWeight: fontWeightRegular,
      display: 'flex',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      left: pxToRem(-15),
      opacity: 1,
      borderRadius: '50%',
      fontSize: size.sm,
    },
  };
}

export { sidenavHeader };
