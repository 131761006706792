function collapseArrow(theme, ownerState) {
  const { palette, typography, transitions, breakpoints, functions } = theme;
  const { noCollapse, transparentSidenav, miniSidenav, open } = ownerState;

  const { grey } = palette;
  const { size } = typography;
  const { pxToRem } = functions;

  return {
    fontSize: `${size.lg} !important`,
    fontWeight: 700,
    marginRight: pxToRem(5),
    transform: open ? 'rotate(0)' : 'rotate(-180deg)',
    color: grey[600],
    transition: transitions.create(['color', 'transform', 'opacity'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('xs')]: {
      display:
        noCollapse || (transparentSidenav && miniSidenav) || miniSidenav
          ? 'none !important'
          : 'block !important',
    },
  };
}

export { collapseArrow };
