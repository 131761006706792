import { ListItem } from '@material-ui/core';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import PropTypes from 'prop-types';
import { actionButtonText } from './Styles';
import { Link } from 'react-router-dom';

export const SideNavActionButton = ({
  color,
  startIcon,
  text,
  route,
  dataTestId,
}) => {
  return (
    <ListItem>
      <MDButton
        data-testid={dataTestId}
        color={color}
        circular={true}
        fullWidth={true}
        size="small"
        variant="contained"
        startIcon={startIcon}
        component={Link}
        to={route}>
        <MDTypography
          variant="h6"
          sx={(theme) =>
            actionButtonText(theme, {
              color,
            })
          }>
          {text}
        </MDTypography>
      </MDButton>
    </ListItem>
  );
};

SideNavActionButton.propTypes = {
  startIcon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  color: PropTypes.string,
  dataTestId: PropTypes.string,
};
