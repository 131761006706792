import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IssuePriority } from 'shared/constants/issues';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';

const PriorityIconSet = ({ color, count }) => (
  <Fragment>
    {[...Array(count)].map((_, index) => (
      <FontAwesomeIcon
        key={index}
        icon={faCaretRight}
        size="lg"
        color={color}
        style={{ marginRight: '3px', marginLeft: index === 0 ? '3px' : '0' }}
      />
    ))}
  </Fragment>
);

PriorityIconSet.propTypes = {
  color: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
};

const propTypes = {
  priority: PropTypes.oneOf([
    IssuePriority.HIGHEST,
    IssuePriority.HIGH,
    IssuePriority.MEDIUM,
    IssuePriority.LOW,
    IssuePriority.LOWEST,
  ]).isRequired,
};

const IssuePriorityIcon2 = ({ priority }) => {
  switch (priority) {
    case IssuePriority.HIGHEST:
      return <PriorityIconSet color="red" count={3} />;
    case IssuePriority.HIGH:
      return <PriorityIconSet color="orange" count={3} />;
    case IssuePriority.MEDIUM:
      return <PriorityIconSet color="orange" count={2} />;
    case IssuePriority.LOW:
      return <PriorityIconSet color="green" count={2} />;
    case IssuePriority.LOWEST:
      return <PriorityIconSet color="green" count={1} />;
    default:
      return;
  }
};

IssuePriorityIcon2.propTypes = propTypes;

export default IssuePriorityIcon2;

//todo:dacao:haw  this is haw version;
/*
      return (
        <Fragment>
          <div>
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="red" />
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="red" />
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="red" />
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="red" />
          </div>
        </Fragment>
      );
    case IssuePriority.HIGH:
      return (
        <Fragment>
          <div>
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="red" />
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="red" />
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="red" />
            <FontAwesomeIcon icon={faCaretRight} size="sm" color="gray" />
          </div>
        </Fragment>
      );
    case IssuePriority.MEDIUM:
      return (
        <Fragment>
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="orange" />
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="orange" />
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="gray" />
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="gray" />
        </Fragment>
      );
    case IssuePriority.LOW:
      return (
        <Fragment>
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="green" />
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="gray" />
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="gray" />
          <FontAwesomeIcon icon={faCaretRight} size="sm" color="gray" />
        </Fragment>
      );
*/
