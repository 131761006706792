import { getUserFullName } from 'shared/utils/name';

export const createUsername = (firstname, lastname, preferredLanguage) => {
  const userFullName = getUserFullName(firstname, lastname, preferredLanguage);
  let OmittedUserFullName = userFullName;
  const byteSize = (str) => new Blob([str]).size;
  if (
    byteSize(OmittedUserFullName[0]) === 1 &&
    OmittedUserFullName.length > 15
  ) {
    OmittedUserFullName = userFullName.substring(0, 14) + '...';
  } else if (
    byteSize(OmittedUserFullName[0]) === 3 &&
    OmittedUserFullName.length > 9
  ) {
    OmittedUserFullName = userFullName.substring(0, 8) + '...';
  }
  return OmittedUserFullName;
};
