import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import MDInputForm from 'components/MDInputForm';

function AutoCompleteFormField({ label, id, ...rest }) {
  return (
    <FormControl fullWidth variant="standard">
      <InputLabel
        shrink
        htmlFor={id}
        sx={{ marginLeft: '10px' }}
        required={rest.required ?? false}>
        {label}
      </InputLabel>
      <MDInputForm variant="standard" id={id} fullWidth {...rest} />
    </FormControl>
  );
}

AutoCompleteFormField.defaultProps = {
  label: '',
};

AutoCompleteFormField.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
};

export default AutoCompleteFormField;
