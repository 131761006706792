import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import MDBootstrapInputRoot from 'components/MDInputBootstrap/MDInputBootstrapRoot';

const MDBootstrapInput = forwardRef(
  ({ error, success, disabled, ...rest }, ref) => (
    <MDBootstrapInputRoot
      {...rest}
      ref={ref}
      InputProps={{ disableUnderline: true }}
      ownerState={{ error, success, disabled }}
    />
  )
);

MDBootstrapInput.displayName = 'MDBootstrapInput';
// Setting default values for the props of MDInput
MDBootstrapInput.defaultProps = {
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the MDInput
MDBootstrapInput.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MDBootstrapInput;
