import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import { cardItem, cardText } from './Styles';
import { useMaterialUIController } from 'context';
import MDBadge from 'components/MDBadge';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { useState } from 'react';
import { CardActionArea } from '@mui/material';

const HomeCard = ({ badgeContent, imgLightSource, imgDarkSource, text }) => {
  const [controller] = useMaterialUIController();
  const [isHovered, setIsHovered] = useState(false);
  const { darkMode } = controller;

  function handleMouseOver() {
    setIsHovered(true);
  }

  function handleMouseOut() {
    setIsHovered(false);
  }

  return (
    <CardActionArea onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      <Card
        variant="outlined"
        sx={(theme) =>
          cardItem(theme, {
            darkMode,
          })
        }>
        <MDBadge
          badgeContent={badgeContent}
          color="error"
          indicator={true}
          size="large"
          circular>
          <MDBox
            component="img"
            //! this could be cleaned better
            src={
              darkMode
                ? isHovered
                  ? imgLightSource
                  : imgDarkSource
                : isHovered
                ? imgDarkSource
                : imgLightSource
            }
            alt="new_sendback"
            width="10rem"
            mr={1}
            pb={1}
          />
        </MDBadge>
        <MDTypography
          variant="h3"
          sx={(theme) =>
            cardText(theme, {
              darkMode,
              isHovered,
            })
          }>
          {text}
        </MDTypography>
      </Card>
    </CardActionArea>
  );
};

HomeCard.propTypes = {
  badgeContent: PropTypes.number.isRequired,
  imgLightSource: PropTypes.node.isRequired,
  imgDarkSource: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
};

export default HomeCard;
